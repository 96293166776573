import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { arrayMoveImmutable } from 'array-move'

import store from 'src/store'

import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux'
import * as moduleActions from './actions'
import * as moduleState from './state'
import createSaga from './sagas'
import { createSelectors } from './selectors'

export const createModuleSlice = (
  config: IModuleConfig,
  initialState: moduleState.IState,
  actions: moduleActions.Actions
) =>
  createSlice({
    name: config.prefix,
    initialState: initialState,
    reducers: {
      mergeData(
        state,
        action: PayloadAction<moduleState.IStateData['objects']['list']>
      ) {
        const { items, previousCursor, nextCursor } = action.payload

        state.data.objects.list = {
          items: [...state.data.objects.list.items, ...items],
          previousCursor,
          nextCursor,
        }
      },

      deleteObjectItem(
        state,
        action: PayloadAction<
          moduleState.IStateData['objects']['list']['items'][number]['uniqId'][]
        >
      ) {
        state.data.objects.list.items = state.data.objects.list.items.filter(
          (o) => !action.payload.find((r) => r === o.uniqId)
        )
      },
      updateItem(state, action: PayloadAction<moduleState.IUpdateObjectItem>) {
        const updatableObjectInd = state.data.objects.list.items.findIndex(
          (obj) => obj.uniqId === action.payload.uniqId
        )

        const { value, key } = action.payload.data

        if (updatableObjectInd >= 0)
          (state.data.objects.list.items[updatableObjectInd][
            key
          ] as moduleState.IListItem[typeof key]) = value
      },
      setList(
        state: moduleState.IState,
        action: PayloadAction<moduleState.IStateData['objects']['list']>
      ) {
        const { items, previousCursor, nextCursor } = action.payload

        state.data.objects.list = {
          items,
          previousCursor,
          nextCursor,
        }
      },
      setListAddFirst(
        state: moduleState.IState,
        action: PayloadAction<
          moduleState.IStateData['objects']['list']['items'][number]
        >
      ) {
        const item = action.payload

        state.data.objects.list.items = [item, ...state.data.objects.list.items]
      },
      setListAddLast(
        state: moduleState.IState,
        action: PayloadAction<
          moduleState.IStateData['objects']['list']['items'][number]
        >
      ) {
        const item = action.payload

        state.data.objects.list.items = [...state.data.objects.list.items, item]
      },
      setItem(
        state: moduleState.IState,
        action: PayloadAction<moduleState.IStateData['objects']['item']>
      ) {
        if (action.payload) {
          state.data.objects.item = { ...action.payload }
        } else {
          state.data.objects.item = undefined
        }
      },
      setChangeObjectList(
        state: moduleState.IState,
        action: PayloadAction<
          moduleState.IStateData['objects']['list']['items']
        >
      ) {
        state.data.objects.list = {
          ...state.data.objects.list,
          items: action.payload,
        }
      },
      // setItemFields(
      //   state: moduleState.IState,
      //   action: PayloadAction<moduleActions.IPutObjectsItemPayload>
      // ) {
      //   if (state.data.objects.item) {
      //     state.data.objects.item = {
      //       ...state.data.objects.item,
      //       values: {
      //         ...state.data.objects.item.values,
      //         ...action.payload.data.fields,
      //       },
      //     }

      //     if (action.payload.data.parentObjectUniqId) {
      //       state.data.objects.item.parentObject = {
      //         icon: null,
      //         objectSchemeIdentifier: '',
      //         parentObjectUniqId: '',
      //         primary: null,
      //         secondary: null,
      //         ...state.data.objects.item.parentObject,
      //         uniqId: action.payload.data.parentObjectUniqId || '',
      //       }
      //     }
      //   }
      // },
      setListItem(
        state: moduleState.IState,
        action: PayloadAction<moduleState.IStateData['objects']['item']>
      ) {
        const objectItem = action.payload
        if (objectItem) {
          state.data.objects.list.items = state.data.objects.list.items.map(
            (item) => {
              if (item.uniqId === objectItem.uniqId) {
                return objectItem
              }

              return item
            }
          )
        }
      },
      // setListItemFields(
      //   state: moduleState.IState,
      //   action: PayloadAction<moduleActions.IPutObjectsItemPayload>
      // ) {
      //   state.data.objects.list.items = state.data.objects.list.items.map(
      //     (item) => {
      //       if (item.uniqId === action.payload.uniqId) {
      //         return {
      //           ...item,

      //           parentObject: action.payload.data.parentObjectUniqId
      //             ? {
      //                 icon: null,
      //                 objectSchemeIdentifier: '',
      //                 parentObjectUniqId: '',
      //                 primary: null,
      //                 secondary: null,
      //                 ...item.parentObject,
      //                 uniqId: action.payload.data.parentObjectUniqId
      //                   ? action.payload.data.parentObjectUniqId
      //                   : item.parentObject?.uniqId || '',
      //               }
      //             : item.parentObject,

      //           values: {
      //             ...item.values,
      //             ...action.payload.data.fields,
      //           },
      //         }
      //       }

      //       return item
      //     }
      //   )
      // },
      setFieldsGroups(
        state: moduleState.IState,
        action: PayloadAction<moduleState.IStateData['fieldsGroups']['list']>
      ) {
        state.data.fieldsGroups.list = action.payload
      },
      setBoardConfig(
        state: moduleState.IState,
        action: PayloadAction<moduleState.IStateData['board']['config']>
      ) {
        state.data.board.config = action.payload
      },
      setTableColumns(
        state: moduleState.IState,
        action: PayloadAction<moduleState.IStateData['table']['columns']>
      ) {
        state.data.table.columns = action.payload
      },

      setTableColumnsPriority(
        state: moduleState.IState,
        action: PayloadAction<moduleActions.ITableColumnsPriorityPayload>
      ) {
        const { identifier, index } = action.payload

        const column = state.data.table.columns.find(
          (column) => identifier === column.identifier
        )

        if (column) {
          const toBeUpdated = arrayMoveImmutable(
            state.data.table.columns.sort(
              (a, b) => a.priority.index - b.priority.index
            ),
            column?.priority.index,
            index
          )
          state.data.table.columns = toBeUpdated.map((column, index) => ({
            ...column,
            index,
            priority: {
              ...column.priority,
              index,
            },
          }))
        }
      },
      setTableColumnsEnable(
        state: moduleState.IState,
        action: PayloadAction<moduleActions.ITableColumnsEnablePayload>
      ) {
        const { identifier, enable } = action.payload

        state.data.table.columns = state.data.table.columns.map((column) => {
          if (identifier === column.identifier) {
            return {
              ...column,
              enable,
            }
          }

          return column
        })
      },
      setTableColumnsWidth(
        state: moduleState.IState,
        action: PayloadAction<moduleActions.ITableColumnsWidthPayload>
      ) {
        const { identifier, width } = action.payload

        state.data.table.columns = state.data.table.columns.map((column) => {
          if (identifier === column.identifier) {
            return {
              ...column,
              width,
            }
          }

          return column
        })
      },
      setBoardStagePriority(
        state: moduleState.IState,
        action: PayloadAction<moduleActions.IBoardStagePriorityPayload>
      ) {
        const { boardIdentifier, choiseId, index } = action.payload

        state.data.board.config.boards = state.data.board.config.boards.map(
          (board) => {
            if (boardIdentifier === board.identifier) {
              const stage = board.stages.find(
                (stage) => stage.choice.id === choiseId
              )

              const toBeUpdatedStages = arrayMoveImmutable(
                board.stages.sort((a, b) => a.index - b.index),
                stage?.index || 0,
                index
              ).map((item, index) => ({ ...item, index }))

              return {
                ...board,
                stages: toBeUpdatedStages,
              }
            }

            return board
          }
        )
      },
      setBoardStageEnable(
        state: moduleState.IState,
        action: PayloadAction<moduleActions.IBoardStageEnablePayload>
      ) {
        const { boardIdentifier, choiseId, enable } = action.payload

        state.data.board.config.boards = state.data.board.config.boards.map(
          (board) => {
            if (boardIdentifier === board.identifier) {
              return {
                ...board,
                stages: board.stages.map((stage) => ({
                  ...stage,
                  enable: stage.choice.id === choiseId ? enable : stage.enable,
                })),
              }
            }

            return board
          }
        )
      },
      setBoardFieldsPriority(
        state: moduleState.IState,
        action: PayloadAction<moduleActions.IBoardFieldsPriorityPayload>
      ) {
        const { boardIdentifier, fieldIdentifier, index } = action.payload

        state.data.board.config.boards = state.data.board.config.boards.map(
          (board) => {
            if (boardIdentifier === board.identifier) {
              const field = board.fields.find(
                (field) => field.identifier === fieldIdentifier
              )

              const toBeUpdatedFields = arrayMoveImmutable(
                board.fields.sort((a, b) => a.index - b.index),
                field?.index || 0,
                index
              ).map((item, index) => ({ ...item, index }))

              return {
                ...board,
                fields: toBeUpdatedFields,
              }
            }

            return board
          }
        )
      },
      setBoardFieldsEnable(
        state: moduleState.IState,
        action: PayloadAction<moduleActions.IBoardFieldsEnablePayload>
      ) {
        const { boardIdentifier, fieldIdentifier, enable } = action.payload

        state.data.board.config.boards = state.data.board.config.boards.map(
          (board) => {
            if (boardIdentifier === board.identifier) {
              return {
                ...board,
                fields: board.fields.map((field) => {
                  if (field.identifier === fieldIdentifier) {
                    return {
                      ...field,
                      enable: enable,
                    }
                  }

                  return field
                }),
              }
            }

            return board
          }
        )
      },
      setSmartLists(
        state,
        action: PayloadAction<moduleState.IStateData['smartList']['list']>
      ) {
        state.data.smartList.list = action.payload
      },
      setSmartListsAppend(
        state,
        action: PayloadAction<moduleState.IStateData['smartList']['list']>
      ) {
        state.data.smartList.list.items = [
          ...state.data.smartList.list.items,
          ...action.payload.items,
        ]
        state.data.smartList.list.nextCursor = action.payload.nextCursor
      },
      setSmartListUpdate(
        state,
        action: PayloadAction<
          moduleState.IStateData['smartList']['list']['items'][number]
        >
      ) {
        state.data.smartList.list.items = state.data.smartList.list.items.map(
          (item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                ...action.payload,
              }
            }

            return item
          }
        )
      },
      setSmartListAdd(
        state,
        action: PayloadAction<
          moduleState.IStateData['smartList']['list']['items'][number]
        >
      ) {
        state.data.smartList.list.items = [
          ...state.data.smartList.list.items,
          action.payload,
        ]
      },
      setSmartListRemove(state, action: PayloadAction<{ uniqId: number }>) {
        state.data.smartList.list.items =
          state.data.smartList.list.items.filter((item) => {
            return item.id !== action.payload.uniqId
          })
      },
      setSmartListItem(
        state,
        action: PayloadAction<
          | moduleState.IStateData['smartList']['list']['items'][number]
          | undefined
        >
      ) {
        state.data.smartList.item = action.payload
      },
      setSmartListItemDefault(
        state,
        action: PayloadAction<
          moduleState.IStateData['smartList']['itemDefault'] | undefined
        >
      ) {
        state.data.smartList.itemDefault = action.payload
      },
      setClear(state: moduleState.IState) {
        state.data = moduleState.getInitialData(config)
        clearAllStateCommunications(state.communication)
      },
    },
    extraReducers: (builder) => {
      builderSagaCommunications<moduleState.IState>(builder, actions)
    },
  })

export type Setters = ReturnType<typeof createModuleSlice>['actions']

export type IStateData = moduleState.IStateData

export interface IModuleConfig {
  prefix: string
  schemeName: string
  _prefix?: string
}

const injectStore = (configure: IModuleConfig) => {
  const config = { ...configure }

  config._prefix = config.prefix
  config.prefix = `storage/objects/${config.prefix}/${config.schemeName}`

  const actions = moduleActions.createActions(config)
  const initialState = moduleState.getInitialState(config, actions)
  const slice = createModuleSlice(config, initialState, actions)

  const { selectCommunication, selectData, selectors, communications } =
    createSelectors(config, actions)
  const saga = createSaga(config, actions, slice.actions, selectors)

  store.injectReducer?.(config.prefix, slice.reducer)
  store.injectSaga?.(config.prefix, saga)

  return {
    actions,
    communications,
    initialState,
    saga,
    selectCommunication,
    selectData,
    selectors,
    slice,
  }
}

const instances = new Map<string, ReturnType<typeof injectStore>>()

export const getStore = (config: IModuleConfig) => {
  const key = `${config.prefix}/${config.schemeName}`
  const store = instances.get(key)
  if (store) {
    return store
  } else {
    const newStore = injectStore(config)
    instances.set(key, newStore)

    return newStore
  }
}
