import axios, { AxiosPromise } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import {
  AuthInterceptorRequestFullfiled,
  DataFormInterceptorRequestFullfiled,
  DataFormInterceptorMetodKey,
} from 'src/services/interceptors/request'
import {
  INormalizeResponse,
  NormalizeInterceptorResponseFullfiled,
  ErrorProcessing,
} from 'src/services/interceptors/response'
import * as locationService from 'src/services/location'

const partner = locationService.getPartner()
const domain = window.settings.BASE_AUTH_DOMAIN

export const baseURL = `//${partner}.${domain}/api`
export const baseURLWithoutCompany = `//${domain}/api`

export type IResponse<data> = INormalizeResponse<data>
export type HTTPPromise<Data> = AxiosPromise<Data>

export const http = axios.create({
  baseURL,
  withCredentials: false,
})

http.interceptors.request.use(DataFormInterceptorRequestFullfiled)

applyCaseMiddleware(http, {
  preservedKeys: (input) => {
    return [DataFormInterceptorMetodKey].includes(input)
  },
})

http.interceptors.response.use(
  NormalizeInterceptorResponseFullfiled,
  ErrorProcessing
)

http.interceptors.request.use(AuthInterceptorRequestFullfiled)
