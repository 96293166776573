import axios, { AxiosPromise } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { AuthInterceptorRequestFullfiled } from 'src/services/interceptors/request'
import {
  INormalizeResponse,
  NormalizeInterceptorResponseFullfiled,
  ErrorProcessing,
} from 'src/services/interceptors/response'
import * as locationService from 'src/services/location'

const parner = locationService.getPartner()
const domain = window.settings.BASE_COMMUNICATION_DOMAIN

export const baseURL = `//${parner}.${domain}/api`

export type IResponse<data> = INormalizeResponse<data>
export type HTTPPromise<Data> = AxiosPromise<Data>

export const http = axios.create({
  baseURL,
  withCredentials: false,
})

applyCaseMiddleware(http)
http.interceptors.response.use(
  NormalizeInterceptorResponseFullfiled,
  ErrorProcessing
)
http.interceptors.request.use(AuthInterceptorRequestFullfiled)
