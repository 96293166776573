import { PossibleFilterOperationsType } from 'src/modals/storageFilters/types'
import * as types from '../types'

export interface IObjectsValue {
  createdAt: string
  fieldSchemeId: number
  floatValue: number | number
  id: number
  index: number
  integerValue: number | null
  objectId: number
  relationshipIdValue: number | null
  scheme: types.ISchema
  textValue: string
  updatedAt: string | null
}

export interface IObjectFilter {
  logic?: string
  conditions?: IObjectFilterCondition[]
  relationship?: string
  negation?: boolean
}

export interface IObjectFilterCondition extends IObjectFilter {
  field?: string
  value?: string | string[]
  id?: number
  text?: string
  operation?: PossibleFilterOperationsType
  negation?: boolean
  insensitive?: boolean
}

export enum EObjectSorting {
  desc = 'desc',
  asc = 'asc',
}

export interface IObjectSorting {
  [key: string]: `${EObjectSorting}`
}

export interface IGetObjectsParams {
  schemeName: string
  cursor?: string
  perPage?: number
  parentObjectUniqId?: number | string | null
  filters?: IObjectFilter | null
  search?: string
  sort?: IObjectSorting | null
  smartListId?: string | number
}

export interface IGetObjectsData {
  createdAt: string
  id: number
  isDeleted: boolean
  objectSchemeId: number
  objectSchemeIdentifier: string

  parentObject?: {
    icon: string | null
    objectSchemeIdentifier: string
    parentObjectUniqId: string
    primary: string | null
    secondary: string | null
    uniqId: string
  }
  projectId: number
  uniqId: string
  updatedAt: string
  primary?: string
  secondary?: string
  icon?: string
  values: IProjectSchemeObjectFormValues
}
export interface IGetObjectsShortData {
  id: number
  identifier: string
  title: string
  children: null
  primaryFieldSchemeId: number
  secondaryFieldSchemeId: number
  iconFieldSchemeId: number
}
export interface IObjectValuesRelation {
  uniqId: string
  primary?: string
  secondary?: string
  icon?: string
}
export interface IProjectSchemeObjectFormValues {
  [key: string]: string
}

export interface IGetObjectsByIdParams {
  uniqId: string
  schemeName: string
}

export interface IDeleteObjectByIdParams {
  uniqIds: string[]
  schemeName: string
}

export interface IPutObjectsParams {
  uniqId: string
  schemeName: string
  data: {
    parentObjectUniqId?: string
    fields: IProjectSchemeObjectFormValues
  }
}

export interface IPostObjectsParams {
  schemeName: string
  data: {
    parentObjectUniqId?: string
    fields: IProjectSchemeObjectFormValues
  }
}

export interface IObjectValidateErrors {
  errors: { [identifier: string]: string[] }
  message: string
}

export interface IGetObjectDataByNumber {
  communicationType?: string
  searchValues: string[]
}
