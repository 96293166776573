import { IGlobalStore } from 'src/types/store'
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'
import { actions as sagaActions, PREFIX } from './actions'

export interface IStateData {
  accounts: IConnectedAccount[]
  users: { items: IUser[]; nextCursor: string | null }
  usersOptions: { items: IUser[]; nextCursor: string | null }
  roles: IRole[]
}

export interface IConnectedAccount {
  id: number
  userId: number
  identifier: string
  internalId: number
  driver: string
  status: string
  createdAt: string
  updatedAt: string
  number?: {
    id?: number
    internalId?: number
    partnerGlobalId?: null
    driver?: string
    regionCode?: string
    phoneNumber?: string
    displayName?: string
    identifier?: string
    active?: boolean
    syncAt?: string
    createdAt?: string
    updatedAt?: string
    status?: string
  }
  userIdentifier: {
    id: 1
    info: {
      avatar: string
      firstName: string
      job_title: string
      lastName: string
    }
  }
}

export interface IUser {
  id: number
  info?: {
    jobTitle?: string
    lastName?: string
    firstName?: string
    avatar?: string
  }
  groups: IRole[]

  identifier?: string
  partner?: {
    name?: string
    subdomain?: string
  }
  createdAt?: Date
}

export interface IRole {
  id: number
  slug: string
  description: string
  createdAt: string
  updatedAt: string
  permissions: string[]
}

export interface IState {
  data: IStateData
  communication: TStateCommunications<typeof sagaActions>
}

export const getInitialData = (): IStateData => {
  return {
    accounts: [],
    users: {
      items: [],
      nextCursor: null,
    },
    usersOptions: {
      items: [],
      nextCursor: null,
    },
    roles: [],
  }
}

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
}

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState
}

export { PREFIX }
