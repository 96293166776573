/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react'

const SENTRY_DSN = window.settings.SENTRY_DSN
const isProduction = !location.hostname.includes('localhost')

if (isProduction) {
  // Sentry.init({
  //   dsn: SENTRY_DSN,
  //   tracesSampleRate: 1.0,
  // })
}

export default Sentry
