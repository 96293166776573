/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put } from 'redux-saga/effects'
import { createSagas } from 'src/helpers/redux'
import { IResponse } from 'src/services/auth/http'

import * as usersAuthService from 'src/services/auth/users'
import * as partnerAuthService from 'src/services/auth/partner'
import * as sessionService from 'src/services/session'
import * as accountsService from 'src/services/accounts'
import * as localStorageService from 'src/services/localStorage'

import { setters } from './index'
import * as state from './state'
import { actions, Actions } from './actions'
import { selectors, communications } from './selectors'

export default createSagas<Actions>(actions, {
  login: function* ({ payload }) {
    const login: IResponse<usersAuthService.ILoginData> = yield call(
      usersAuthService.login,
      payload
    )

    sessionService.setSession({
      accessToken: login.data.accessToken,
      expiresIn: login.data.expiresIn,
      tokenType: login.data.tokenType,
      userId: login.data.user.id,
    })

    yield put(
      setters.setSession({
        userId: login.data.user.id,
        accessToken: login.data.accessToken,
        isAfterLogin: true,
      })
    )

    yield put(actions.getProfile.pending())
  },

  getRefreshToken: function* ({ payload }) {
    const refreshToken: IResponse<usersAuthService.IGetRefreshTokenData> =
      yield call(usersAuthService.getRefreshToken)
    sessionService.setSession({
      accessToken: refreshToken.data.accessToken,
      expiresIn: refreshToken.data.expiresIn,
    })
    yield put(
      setters.setRefreshToken({
        accessToken: refreshToken.data.accessToken,
      })
    )
  },
  getAccounts: function* ({ payload }) {
    const accounts: IResponse<state.IConnectedAccount[]> = yield call(
      accountsService.getAccounts,
      {
        ...payload,
      }
    )

    yield put(setters.setConnectedAccounts(accounts.data))
  },

  logout: function* ({ payload }) {
    sessionService.setSession({
      accessToken: undefined,
      expiresIn: undefined,
      tokenType: undefined,
      userId: undefined,
    })

    localStorageService.clearStorage()

    yield put(
      setters.setSession({
        userId: undefined,
        accessToken: undefined,
        isAfterLogin: false,
      })
    )

    yield put(setters.setProfile(undefined))
  },

  getRoles: function* () {
    const roles: IResponse<state.IRole[]> = yield call(
      usersAuthService.getAllAvailableRoles
    )

    yield put(setters.setRoles(roles.data))
  },

  inviteUsers: function* ({ payload }) {
    yield call(usersAuthService.inviteUsers, payload)
  },

  getAllUsers: function* ({ payload }) {
    const users: IResponse<state.IUser[]> = yield call(
      usersAuthService.getAllUsers,
      payload
    )

    yield put(setters.setAllUsers(users))
  },

  loadMoreUsers: function* ({ payload }) {
    const users: IResponse<state.IUser[]> = yield call(
      usersAuthService.getAllUsers,
      payload
    )

    yield put(setters.mergeUsers(users))
  },

  editUser: function* ({ payload }) {
    const editedUser: IResponse<state.IUser> = yield call(
      usersAuthService.editUserData,
      {
        ...payload,
      }
    )

    yield put(setters.setEditedUser(editedUser.data))
  },

  getProfile: function* ({ payload }) {
    const session = sessionService.getSession()

    if (session.userId) {
      const user: IResponse<usersAuthService.IGetAccountData> = yield call(
        usersAuthService.getAccount
      )

      localStorageService.setStorage({
        user: user.data,
      })

      yield put(setters.setProfile(user.data))
    }
  },

  editProfileAvatar: function* ({ payload }) {
    const userAvatar: IResponse<usersAuthService.IGetUserData> = yield call(
      usersAuthService.editAvatarProfile,
      payload
    )

    yield put(setters.setProfileInfo(userAvatar.data?.info))
  },

  editProfile: function* ({ payload }) {
    const user: IResponse<usersAuthService.IGetUserData> = yield call(
      usersAuthService.editProfileData,
      payload
    )

    yield put(setters.setProfileInfo(user.data?.info))
  },

  getBillingStat: function* ({ payload }) {
    const billingStat: IResponse<partnerAuthService.IGetBillingStatData> =
      yield call(partnerAuthService.getBillingStat, payload)

    yield put(setters.setBillingStat(billingStat.data))
  },
})
