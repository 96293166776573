/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { createSagas } from 'src/helpers/redux'
import * as storageObjectsHelpers from 'src/helpers/storageObjects'

import { IResponse } from 'src/services/storage/project/http'
import * as schemaService from 'src/services/storage/project/scheme'
import * as smartListService from 'src/services/storage/project/scheme/smart-list'
import * as stageSortService from 'src/services/storage/project/scheme/objects/stage-sort'
import * as appStore from 'src/store/modules/app'
import { IStateData as AppStoreState } from 'src/store/modules/app/state'
import { IModuleConfig } from './index'
import * as state from './state'
import { Actions, createFullfiledAction } from './actions'
import { Setters } from './index'
import { Selectors } from './selectors'
import { setSession, getSession, IStorageItem } from 'src/services/session'
import { selectData } from '../fields/selectors'
import { ICommunication } from 'src/types/redux'

export default (
  config: IModuleConfig,
  actions: Actions,
  setters: Setters,
  selectors: Selectors
) =>
  createSagas<Actions>(actions, {
    getMoreObjectList: function* ({ payload }) {
      const receivedObjects: IResponse<schemaService.IGetObjectsData[]> =
        yield call(schemaService.getObjects, {
          schemeName: config.schemeName,
          ...payload,
        })

      yield put(
        setters.mergeData({
          items: receivedObjects.data,
          previousCursor: receivedObjects.meta.prevCursor,
          nextCursor: receivedObjects.meta.nextCursor,
        })
      )
    },
    changeObjectList: function* ({ payload }) {
      yield put(setters.setChangeObjectList(payload))
    },
    deleteObjectItem: function* ({ payload }) {
      yield call(schemaService.deleteObject, payload)
      yield put(setters.deleteObjectItem(payload.uniqIds))
    },
    updateObjectItem: function* ({ payload }) {
      yield put(setters.updateItem(payload))
    },
    getObjectsList: {
      effect: 'latest',
      pending: function* ({ payload }) {
        if (payload.search) {
          yield delay(1000)
        }
        const objectList: IResponse<schemaService.IGetObjectsData[]> =
          yield call(schemaService.getObjects, {
            schemeName: config.schemeName,
            ...payload,
          })

        yield put(
          setters.setList({
            items: objectList.data,
            previousCursor: objectList.meta.prevCursor,
            nextCursor: objectList.meta.nextCursor,
          })
        )
      },
      rejected: function* () {
        yield put(
          setters.setList({
            items: [],
            previousCursor: null,
            nextCursor: null,
          })
        )
      },
    },
    getObjectsItem: {
      effect: 'latest',
      saga: function* ({ payload }) {
        const { uniqId } = payload

        const objectItem: IResponse<schemaService.IGetObjectsData> = yield call(
          schemaService.getObjectsById,
          {
            schemeName: config.schemeName,
            uniqId,
          }
        )

        yield put(setters.setItem(objectItem.data))
      },
    },
    postObjectsItem: function* ({ payload }) {
      const { data, fullfiledAction, fullfiledActionContext } = payload
      const item: IResponse<schemaService.IGetObjectsData> = yield call(
        schemaService.postObjects,
        {
          schemeName: config.schemeName,
          data,
        }
      )

      yield put(setters.setListAddFirst(item.data))

      if (fullfiledAction) {
        if (typeof fullfiledAction === 'string') {
          yield put(
            createFullfiledAction(fullfiledAction, {
              data: item.data,
              context: fullfiledActionContext,
            })
          )
        }
        if (typeof fullfiledAction === 'object' && fullfiledAction.type) {
          yield put(fullfiledAction)
        }
      }
    },

    changeListItem: function* ({ payload }) {
      yield put(setters.setListItem(payload))
    },

    putObjectsItem: {
      effect: 'every',
      saga: function* ({ payload }) {
        const { uniqId, data, fullfiledAction, fullfiledActionContext } =
          payload

        const item: IResponse<state.IListItem> = yield call(
          schemaService.putObjects,
          {
            schemeName: config.schemeName,
            uniqId,
            data,
          }
        )
        // yield put(setters.setListItemFields(payload))
        // yield put(setters.setItemFields(payload))
        yield put(setters.setItem(item.data))

        if (fullfiledAction) {
          if (typeof fullfiledAction === 'string') {
            yield put(
              createFullfiledAction(fullfiledAction, {
                data: item.data,
                context: fullfiledActionContext,
              })
            )
          }
          if (typeof fullfiledAction === 'object' && fullfiledAction.type) {
            yield put(fullfiledAction)

            // const type = fullfiledAction.type.replace('/pending','').split('/').pop()

            //   let loading = true
            //   while(loading){
            //     //@ts-ignore
            //     const selectorsComm = yield select(selectors.communications)
            //     const status:ICommunication | undefined = selectorsComm[type || ''];
            //     if (status == undefined){
            //       loading = false
            //       break
            //     }
            //     console.debug('>>>',type,status)
            //     yield delay(500)
            //     console.debug({ uniqId, wait:type })

            //     if (status.state === 3 || status.state === 2){
            //       loading = false
            //     }
            //   }
          }
        }
      },
    },

    getFieldsGroups: function* ({ payload }) {
      const { limit, cursor } = payload

      const fieldsGroups: IResponse<schemaService.IFieldsGroupData[]> =
        yield call(schemaService.getFieldsGroups, {
          schemeName: config.schemeName,
          limit,
          cursor,
        })

      yield put(
        setters.setFieldsGroups({
          items: fieldsGroups.data,
          previousCursor: fieldsGroups.previousCursor,
          nextCursor: fieldsGroups.nextCursor,
        })
      )

      yield put(actions.syncTableColumns.pending())
    },

    setTableColumnsPriority: {
      effect: 'every',
      saga: function* ({ payload }) {
        const { identifier, index } = payload

        yield put(
          setters.setTableColumnsPriority({
            identifier,
            index,
          })
        )
        yield put(actions.saveTableColumns.pending())
      },
    },

    setTableColumnsEnable: {
      effect: 'every',
      saga: function* ({ payload }) {
        const { identifier, enable } = payload

        yield put(
          setters.setTableColumnsEnable({
            identifier,
            enable,
          })
        )
        yield put(actions.saveTableColumns.pending())
      },
    },
    setTableColumnsWidth: {
      effect: 'every',
      saga: function* ({ payload }) {
        const { identifier, width } = payload

        yield put(
          setters.setTableColumnsWidth({
            identifier,
            width,
          })
        )
        yield put(actions.saveTableColumns.pending())
      },
    },

    setBoardStagePriority: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield put(setters.setBoardStagePriority(payload))
        yield put(actions.saveTableColumns.pending())
      },
    },
    setBoardStageEnable: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield put(setters.setBoardStageEnable(payload))
        yield put(actions.saveTableColumns.pending())
      },
    },
    setBoardFieldsPriority: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield put(setters.setBoardFieldsPriority(payload))
        yield put(actions.saveTableColumns.pending())
      },
    },
    setBoardFieldsEnable: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield put(setters.setBoardFieldsEnable(payload))
        yield put(actions.saveTableColumns.pending())
      },
    },
    setBoardCardUpdateFulfiled: function* ({ payload }) {
      yield put(setters.setListItem(payload.data))
    },
    setBoardCardCreateFulfiled: function* ({ payload }) {
      yield put(setters.setListAddLast(payload.data))
    },

    postStageSortStart: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield call(stageSortService.postStageSortStart, payload)
      },
    },
    postStageSortEnd: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield call(stageSortService.postStageSortEnd, payload)
      },
    },
    postStageSortBefore: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield call(stageSortService.postStageSortBefore, payload)
      },
    },
    postStageSortAfter: {
      effect: 'every',
      saga: function* ({ payload }) {
        yield call(stageSortService.postStageSortAfter, payload)
      },
    },

    syncTableColumns: {
      effect: 'every',
      saga: function* () {
        const smartListItem: state.IStateData['smartList']['item'] =
          yield select(selectors.smartListItem)

        const smartListItemDefault: state.IStateData['smartList']['itemDefault'] =
          yield select(selectors.smartListItemDefault)

        const userOptions =
          smartListItem?.userOptions || smartListItemDefault?.userOptions

        const fieldsGroups: state.IStateData['fieldsGroups']['list'] =
          yield select(selectors.fieldsGroupsList)

        const schemes: AppStoreState['schemas'] = yield select(
          appStore.selectors.selectors.schemas
        )

        const fieldsGroupsColumns = storageObjectsHelpers.setupColumns({
          schemaName: config.schemeName,
          fieldsGroups: fieldsGroups.items,
          schemas: schemes,
          columns: userOptions?.columns,
        })

        const fieldsGroupsBoard = storageObjectsHelpers.setupBoard({
          schemaName: config.schemeName,
          fieldsGroups: fieldsGroups.items,
          schemas: schemes,
          boardConfig: userOptions?.board,
        })

        yield put(setters.setBoardConfig(fieldsGroupsBoard))
        yield put(setters.setTableColumns(fieldsGroupsColumns))

        yield put(actions.cacheStorage.pending())
      },
    },
    saveTableColumns: {
      effect: 'latest',
      saga: function* () {
        yield delay(2000)

        const smartListItem: state.IStateData['smartList']['item'] =
          yield select(selectors.smartListItem)

        const smartListItemDefault: state.IStateData['smartList']['item'] =
          yield select(selectors.smartListItemDefault)

        const columns: state.IStateData['table']['columns'] = yield select(
          selectors.tableColumns
        )
        const board: state.IStateData['board']['config'] = yield select(
          selectors.boardConfig
        )
        if (smartListItem) {
          const smartList: IResponse<smartListService.IPutSmartListData> =
            yield call(smartListService.putSmartList, {
              schemeName: config.schemeName,
              uniqId: smartListItem.id,
              data: {
                type: smartListItem.type,
                title: smartListItem.title,
                filter: smartListItem.filter,
                objectIds: smartListItem.objects.map((item) => item.uniqId),
                userOptions: {
                  // ...smartListItem.userOptions,
                  columns: columns.map((column) => ({
                    identifier: column.identifier,
                    enable: column.enable,
                    width: column.width,
                    index: column.priority.index,
                  })),

                  board,
                },
              },
            })
          yield put(setters.setSmartListItem(smartList.data))
        } else {
          const smartListDefault: IResponse<smartListService.IPutSmartListData> =
            yield call(smartListService.putSmartListDefault, {
              schemeName: config.schemeName,
              data: {
                userOptions: {
                  columns: columns.map((column) => ({
                    identifier: column.identifier,
                    enable: column.enable,
                    width: column.width,
                    index: column.priority.index,
                  })),

                  board,
                },
              },
            })
          yield put(setters.setSmartListItemDefault(smartListDefault.data))
        }

        yield put(actions.syncTableColumns.pending())
      },
    },
    getSmartList: function* ({ payload }) {
      const smartList: IResponse<smartListService.IGetSmartListData> =
        yield call(smartListService.getSmartList, {
          schemeName: config.schemeName,
          ...payload,
        })

      if (payload.cursor) {
        yield put(
          setters.setSmartListsAppend({
            items: smartList.data,
            nextCursor: smartList.meta.nextCursor,
          })
        )
      } else {
        yield put(
          setters.setSmartLists({
            items: smartList.data,
            nextCursor: smartList.meta.nextCursor,
          })
        )
      }
    },

    getSmartListItem: {
      pending: function* ({ payload }) {
        const smartListItem: IResponse<smartListService.IGetSmartListItemData> =
          yield call(smartListService.getSmartListItem, {
            schemeName: config.schemeName,
            ...payload,
          })
        yield put(setters.setSmartListItem(smartListItem.data))
        yield put(actions.syncTableColumns.pending())
      },
      clear: function* () {
        yield put(setters.setSmartListItem(undefined))
        yield put(actions.syncTableColumns.pending())
      },
    },
    getSmartListItemDefault: {
      pending: function* ({ payload }) {
        try {
          const smartListItem: IResponse<smartListService.IGetSmartListItemData> =
            yield call(smartListService.getSmartListItemDefault, {
              schemeName: config.schemeName,
              ...payload,
            })
          yield put(setters.setSmartListItemDefault(smartListItem.data))
        } catch (error) {
          const smartListItem: IResponse<smartListService.IGetSmartListItemData> =
            yield call(smartListService.postSmartListDefault, {
              schemeName: config.schemeName,
              data: {},
            })

          yield put(setters.setSmartListItemDefault(smartListItem.data))
        }
        yield put(actions.syncTableColumns.pending())
      },
      clear: function* () {
        yield put(setters.setSmartListItemDefault(undefined))
        yield put(actions.syncTableColumns.pending())
      },
    },
    setSmartListItem: {
      pending: function* ({ payload }) {
        yield put(setters.setSmartListItem(payload))
        yield put(actions.syncTableColumns.pending())
      },
      clear: function* () {
        yield put(setters.setSmartListItem(undefined))
        yield put(actions.syncTableColumns.pending())
      },
    },
    setSmartListItemDefault: {
      pending: function* ({ payload }) {
        yield put(setters.setSmartListItemDefault(payload))
        yield put(actions.syncTableColumns.pending())
      },
      clear: function* () {
        yield put(setters.setSmartListItemDefault(undefined))
        yield put(actions.syncTableColumns.pending())
      },
    },
    putSmartList: function* ({ payload }) {
      const smartList: IResponse<smartListService.IPutSmartListData> =
        yield call(smartListService.putSmartList, {
          schemeName: config.schemeName,
          ...payload,
        })
      yield put(setters.setSmartListItem(smartList.data))
      yield put(
        appStore.actions.navigate({
          search: {
            smartListSelectedId: smartList.data.id,
          },
        })
      )
      yield put(setters.setSmartListUpdate(smartList.data))
    },

    postSmartList: function* ({ payload }) {
      const smartList: IResponse<smartListService.IPutSmartListData> =
        yield call(smartListService.postSmartList, {
          schemeName: config.schemeName,
          ...payload,
        })
      yield put(setters.setSmartListItem(smartList.data))
      yield put(setters.setSmartListAdd(smartList.data))
      yield put(
        appStore.actions.navigate({
          search: {
            smartListSelectedId: smartList.data.id,
          },
        })
      )
    },

    deleteSmartList: function* ({ payload }) {
      const smartList: IResponse<smartListService.IPutSmartListData> =
        yield call(smartListService.deleteSmartList, {
          schemeName: config.schemeName,
          ...payload,
        })
      yield put(setters.setSmartListItem(undefined))
      yield put(
        appStore.actions.navigate({
          search: {
            smartListSelectedId: undefined,
          },
        })
      )
      yield put(setters.setSmartListRemove(payload))
    },
    logging: {
      effect: (pattern, saga) => takeLatest('*', saga),
      controlled: true,
      saga: function* (data) {
        if (
          data.type.includes(config.prefix) &&
          !data.type.includes('cacheStorage')
        ) {
          yield put(actions.cacheStorage.pending())
        }
      },
    },
    cacheStorage: {
      effect: 'latest',
      controlled: true,
      saga: function* (data) {
        if (data.type.includes(config.prefix)) {
          yield delay(1000)
          try {
            const stateData: state.IStateData = yield select(selectors.data)

            localStorage.setItem(
              config.prefix,
              JSON.stringify({
                ...stateData,

                objects: {
                  ...stateData.objects,
                  item: undefined,
                  list: {
                    items: [], //stateData.objects.list.items.slice(0,25)
                    nextCursor: null,
                    previousCursor: null,
                  },
                },

                smartList: {
                  ...stateData.smartList,
                  item: undefined,
                  list: {
                    items: stateData.smartList.list.items.slice(0, 5),
                  },
                },
                table: {
                  ...stateData.table,
                  filters: {},
                },
              } as state.IStateData)
            )
          } catch (e) {
            console.debug(e)
          }
        }
      },
    },
  })
