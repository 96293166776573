import { createHooksActions, createHooksSelectors } from 'src/helpers/redux'
import * as storeModule from 'src/store/modules/storage/objects'

// export * from 'src/store/modules/storage/objects'
export * from 'src/store/modules/storage/objects/state'

const useStore = (config: storeModule.IModuleConfig) => {
  const store = storeModule.getStore(config)

  const useSelect = createHooksSelectors<typeof store.selectors>(
    store.selectors
  )

  const useAction = createHooksActions<typeof store.actions>(
    store.actions,
    store.communications
  )

  return {
    store,
    useSelect,
    useAction,
  }
}

const instances = new Map<string, ReturnType<typeof useStore>>()

export const useStorageObjectsStore = (config: storeModule.IModuleConfig) => {
  const key = `${config.prefix}/${config.schemeName}`
  const hooks = instances.get(key)

  if (hooks) {
    return hooks
  }

  return useStore(config)
}
