import { createSelector } from 'reselect'
import { createCommunicationSelectors } from 'src/helpers/redux'
import { actions } from './actions'

import { IState, PREFIX, TGlobalStateWithService } from './state'

export const tokenSelect = (
  state: TGlobalStateWithService
): IState['data']['token'] => state[PREFIX]?.data?.token

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions)

export const selectors = {
  token: createSelector(tokenSelect, (state) => state),
}
