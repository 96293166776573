import * as app from './modules/app'
import * as account from './modules/account'
import * as users from './modules/users'

const reducers = {
  ...app.reducers,
  ...account.reducers,
  ...users.reducers,
}

export default reducers
