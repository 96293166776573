/* eslint-disable @typescript-eslint/no-unused-vars */
import { ListenerMiddleware } from '@reduxjs/toolkit'

import { actions as appActions } from '../modules/app/actions'
import { isDevelopment } from 'src/services/location'

interface IAction {
  type: string
  payload: {
    [key: string]: unknown
  }
}

export function createToastMiddleware(): ListenerMiddleware {
  const isNotRejectedActions: string[] = []

  return (store) => (next: (action: IAction) => void) => (action: IAction) => {
    const returnedValue = next(action)
    const isRejected =
      !isNotRejectedActions.includes(action.type) &&
      action.type.includes('/rejected')

    if (isRejected && isDevelopment) {
      store.dispatch(
        appActions.tost({
          type: 'error',
          title: action.type,
          description:
            //@ts-ignore
            action.payload?.response?.data?.message ||
            action.payload?.message ||
            '',
        })
      )
    }

    return returnedValue
  }
}
