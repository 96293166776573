/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put } from 'redux-saga/effects'
import { createSagas } from 'src/helpers/redux'
import { IResponse } from 'src/services/auth/http'

import * as usersAuthService from 'src/services/auth/users'
import * as sessionService from 'src/services/session'
import * as accountsService from 'src/services/accounts'
import * as localStorageService from 'src/services/localStorage'

import { setters } from './index'
import * as state from './state'
import { actions, Actions } from './actions'
import { selectors, communications } from './selectors'

export default createSagas<Actions>(actions, {
  getAccounts: function* ({ payload }) {
    const accounts: IResponse<state.IConnectedAccount[]> = yield call(
      accountsService.getAccounts,
      {
        ...payload,
      }
    )

    yield put(setters.setConnectedAccounts(accounts.data))
  },

  getRoles: function* () {
    const roles: IResponse<state.IRole[]> = yield call(
      usersAuthService.getAllAvailableRoles
    )

    yield put(setters.setRoles(roles.data))
  },

  inviteUsers: function* ({ payload }) {
    yield call(usersAuthService.inviteUsers, payload)
  },

  getUsers: function* ({ payload }) {
    const users: IResponse<state.IUser[]> = yield call(
      usersAuthService.getAllUsers,
      payload
    )

    yield put(
      setters.setUsers({
        items: users.data,
        nextCursor: users.meta?.nextCursor,
      })
    )
  },

  loadMoreUsers: function* ({ payload }) {
    const users: IResponse<state.IUser[]> = yield call(
      usersAuthService.getAllUsers,
      payload
    )

    yield put(
      setters.setUsersAppend({
        items: users.data,
        nextCursor: users.meta?.nextCursor,
      })
    )
  },

  getUsersOptions: function* ({ payload }) {
    const users: IResponse<state.IUser[]> = yield call(
      usersAuthService.getAllUsers,
      payload
    )

    yield put(
      setters.setUsersOptions({
        items: users.data,
        nextCursor: users.meta?.nextCursor,
      })
    )
  },

  loadMoreUsersOptions: function* ({ payload }) {
    const users: IResponse<state.IUser[]> = yield call(
      usersAuthService.getAllUsers,
      payload
    )

    yield put(
      setters.setUsersOptionsAppend({
        items: users.data,
        nextCursor: users.meta?.nextCursor,
      })
    )
  },
  // editUser: function* ({ payload }) {
  //   const editedUser: IResponse<state.IUser> = yield call(
  //     usersAuthService.editUserData,
  //     {
  //       ...payload,
  //     }
  //   )

  //   yield put(setters.setEditedUser(editedUser.data))
  // },
})
