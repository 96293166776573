import { createHooksActions, createHooksSelectors } from 'src/helpers/redux'
import * as store from 'src/store/modules/account'

export { store }

export * from 'src/store/modules/account/state'

export const useAccountSelect = createHooksSelectors<
  typeof store.selectors.selectors
>(store.selectors.selectors)

export const useAccountAction = createHooksActions<typeof store.actions>(
  store.actions,
  store.selectors.communications
)
