export enum ECommState {
  clear,
  pending,
  rejected,
  fulfilled,
}

export interface ICommunication {
  state: ECommState
  error?: Error
}

export interface IStateWithCommunication {
  communication: { [key: string]: ICommunication }
}
