import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import store from 'src/store'

import { actions as moduleActions } from './actions'
import * as moduleState from './state'
import moduleSagas from './sagas'
import * as moduleSelectors from './selectors'
import { CallState, ICall, IToWhomCall, ToolConfiguration } from './types'
import { builderSagaCommunications } from 'src/helpers/redux'
import { IResponse } from 'src/services/accounts/http'

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setWidgetTool(state, action: PayloadAction<ToolConfiguration>) {
      state.widgetOptions.tool = action.payload
    },

    setTwillioState(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload
    },
    setCallState(state, action: PayloadAction<CallState>) {
      state.widgetOptions.callState = action.payload
    },
    setMoreHistoryCall(state, action: PayloadAction<IResponse<ICall[]>>) {
      state.data.callHistory.data = [
        ...(state.data.callHistory?.data ?? []),
        ...action.payload.data,
      ]
      state.data.callHistory.cursor = action.payload.meta.nextCursor
    },
    setWidgetState(
      state,
      action: PayloadAction<moduleState.IState['widgetOptions']>
    ) {
      state.widgetOptions = action.payload
    },

    setMicroState(state, action: PayloadAction<boolean>) {
      state.widgetOptions.microMuted = action.payload
    },

    setUserCall(state, action: PayloadAction<IToWhomCall>) {
      state.data.user = { ...state.data.user, ...action.payload }
    },
    resetUserState(state) {
      state.data.user = { number: '' }
    },

    setHistoryCalls(state, action: PayloadAction<IResponse<ICall[]>>) {
      state.data.callHistory.data = action.payload.data
      state.data.callHistory.cursor = action.payload.meta.nextCursor
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions)
  },
})

export const setters = slice.actions
export const actions = moduleActions
export const reducers = { [moduleState.PREFIX]: slice.reducer }
export const sagas = moduleSagas
export const state = moduleState
export const selectors = moduleSelectors

export const injectStore = () => {
  store.injectReducer?.(moduleState.PREFIX, slice.reducer)
  store.injectSaga?.(moduleState.PREFIX, sagas)
}
