/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put } from 'redux-saga/effects'
import { actions, Actions } from './actions'
import { createSagas } from 'src/helpers/redux'

import * as twilioService from 'src/services/communication/twilio'

import { setters } from './index'
import { IResponse } from 'src/services/mail/http'

export default createSagas<Actions>(actions, {
  setupClient: function* () {
    yield call(twilioService.startupClient)
  },
})
