import React from 'react'

import Stack from '@mui/material/Stack'

import { useSearchQuery } from 'src/hooks/useSearchQuery'
import UserIcon from 'src/layouts/components/UserIcon'
import { useCommunicationSelect } from 'src/hooks/useCommunication'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { CustomPalette } from 'src/theme/palette'
import { CallState } from 'src/store/modules/communication/types'
import {
  disconnectHandler,
  incomingCallAccept,
  rejectIncomingCall,
} from 'src/services/communication/twilio'
import { useOnlineState } from 'src/hooks/useOnlineState'
import { Tooltip } from '@mui/material'

const CommunicationWidgetMini = () => {
  const { setQuery } = useSearchQuery()
  const storeData = useCommunicationSelect.storeData()
  const twillioEnabled = useCommunicationSelect.twillioEnabled()
  const storeWidgetOptions = useCommunicationSelect.widgetOptions()
  const { name, number, secondary, avatarUrl } = storeData.user

  const isOnline = useOnlineState({})

  const openMobileModal = () => {
    if (isOnline && twillioEnabled) {
      setQuery({
        modal: 'phone',
      })
    }
  }

  React.useEffect(() => {
    if (storeData.user.number) {
      setQuery({
        modal: 'phone',
      })
    }
  }, [storeData.user.number])

  const onDisconnectHandler = () => {
    disconnectHandler()
  }

  const onAcceptIncomingCall = () => {
    incomingCallAccept()
  }

  const onRejectIncomingCallHandler = () => {
    rejectIncomingCall()
  }

  const tooltipTitle = !isOnline
    ? 'Connection lost. Check your internet connection.'
    : twillioEnabled
    ? ''
    : 'Something went wrong with your Twillio device.'

  return (
    <Tooltip title={tooltipTitle}>
      <Stack
        px={4}
        sx={{
          width: isOnline ? (number ? '382px' : '62px') : '62px',
          transition: '0.5s',
          height: '62px',
          borderRadius: '5px',
          cursor: 'pointer',
          background: (theme) =>
            isOnline && twillioEnabled
              ? number
                ? '#030426'
                : theme.palette.success.main
              : theme.palette.grey[300],
        }}
        onClick={openMobileModal}
        alignContent={'center'}
        justifyContent={'center'}
      >
        {!storeData.user.number && (
          <Stack sx={{ height: '80%' }} alignItems="center">
            <UserIcon
              fontSize={30}
              color="white"
              width="100px"
              height="100px"
              icon="gridicons:phone"
            />
          </Stack>
        )}
        {storeData.user.number && (
          <Stack
            direction="row"
            flex={1}
            justifyContent={'flex-end'}
            alignItems="center"
            sx={{
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Stack
              direction="row"
              flexBasis={0}
              flexGrow={1}
              flexShrink={1}
              columnGap={1}
            >
              <Avatar
                sx={{ width: '40px', height: '40px' }}
                alt={name}
                src={avatarUrl || ''}
              />
              <Stack
                sx={{ width: 0 }}
                flexBasis={0}
                flexGrow={1}
                flexShrink={1}
              >
                <Stack
                  flexBasis={0}
                  direction="row"
                  flexGrow={1}
                  flexShrink={1}
                  columnGap={1}
                >
                  <Typography
                    color={'grey.100'}
                    fontWeight={700}
                    fontSize={14}
                    textOverflow={'ellipsis'}
                    noWrap
                  >
                    {name}
                  </Typography>
                  {secondary && (
                    <Typography noWrap fontSize={14} color={'grey.600'}>
                      {secondary}
                    </Typography>
                  )}
                </Stack>
                <Typography fontSize={12} color={'grey.600'}>
                  {number}
                </Typography>
              </Stack>
            </Stack>

            {storeWidgetOptions.callState === CallState.INCOMING && (
              <Stack direction="row" spacing={2}>
                <IconButton
                  color="success"
                  onClick={onAcceptIncomingCall}
                  sx={{
                    background: (theme) => theme.palette.success.main,
                    '&:hover': {
                      background: (theme) => theme.palette.success.main,
                    },
                  }}
                >
                  <UserIcon icon={'ic:baseline-phone'} color="white" />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={onRejectIncomingCallHandler}
                  sx={{
                    background: (theme) => theme.palette.error.main,
                    '&:hover': {
                      background: (theme) => theme.palette.error.main,
                    },
                  }}
                >
                  <UserIcon
                    icon={'material-symbols:phone-disabled'}
                    color="white"
                  />
                </IconButton>
              </Stack>
            )}

            {(storeWidgetOptions.callState === CallState.ANSWERED ||
              storeWidgetOptions.callState === CallState.OUTGOING) && (
              <Stack direction="row" spacing={2}>
                <IconButton
                  sx={{
                    background: (theme) =>
                      (theme.palette as CustomPalette).UI.main,
                    '&:hover': {
                      background: (theme) =>
                        (theme.palette as CustomPalette).UI.main,
                    },
                  }}
                >
                  <UserIcon icon={'material-symbols:mic'} color="white" />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={onDisconnectHandler}
                  sx={{
                    background: (theme) => theme.palette.error.main,
                    '&:hover': {
                      background: (theme) => theme.palette.error.main,
                    },
                  }}
                >
                  <UserIcon
                    icon={'material-symbols:phone-disabled'}
                    color="white"
                  />
                </IconButton>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Tooltip>
  )
}

export default CommunicationWidgetMini
