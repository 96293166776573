/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import 'src/services/communication/twilio'
import Layout from 'src/layouts/Layout'
import { useSettings } from 'src/hooks/useSettings'
import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount'
import { useAppSelect } from 'src/hooks/useApp'
import { SearchAsyncModal } from 'src/modals/SearchAsyncModal'
import VerticalAppBarContent from './components/vertical/AppBarContent'
import { useAppAction } from 'src/hooks/useApp'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import { useTwilioActions } from 'src/hooks/useTwilio'

interface Props {
  contentHeightFixed?: boolean
}

const UserLayout = ({ contentHeightFixed }: Props) => {
  const navigate = useNavigate()
  const isAfterLogin = useAccountSelect.isAfterLogin()
  const isUser = useAccountSelect.isUser()
  const getProfile = useAccountAction.getProfile()
  const getShemas = useAppAction.getSchemas()
  const setupClinet = useTwilioActions.setupClient()
  const getShemasStatus = useAppAction.getSchemas.status()
  const appSchemas = useAppSelect.schemas()
  const [fulfiledSchemas, setFulfiledSchemas] = React.useState(
    appSchemas.length > 0
  )
  const getRefreshToken = useAccountAction.getRefreshToken()
  const getRefreshTokenStatus = useAccountAction.getRefreshToken.status()
  const isRefreshedToken = !isAfterLogin
    ? !getRefreshTokenStatus.isClear && !getRefreshTokenStatus.isPending
    : true

  React.useEffect(() => {
    if (!fulfiledSchemas && getShemasStatus.isFulfilled) {
      setFulfiledSchemas(true)
    }
  }, [fulfiledSchemas, getShemasStatus.isFulfilled])

  React.useEffect(() => {
    if (!isAfterLogin) {
      getRefreshToken()
    }
  }, [isAfterLogin])

  React.useEffect(() => {
    if (isUser) {
      if (isRefreshedToken) {
        getShemas({})
        getProfile()
        setupClinet()
      }
    } else {
      navigate('/login')
    }
  }, [isUser, getProfile, isRefreshedToken])

  const { settings, saveSettings } = useSettings()

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isLoading =
    (!isAfterLogin ? getRefreshTokenStatus.isPending : false) ||
    !fulfiledSchemas

  if (isLoading)
    return (
      <Stack
        sx={{
          flex: 1,
          display: 'flex',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Stack>
    )

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        appBar: {
          content: (props: { pathggleNavVisibility: any }) => (
            <VerticalAppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.pathggleNavVisibility}
            />
          ),
        },
      }}
    >
      <Stack mx={{ xs: 0, md: 6 }} sx={{ height: '100%' }} flex={1}>
        <Outlet />
      </Stack>
      <SearchAsyncModal />
    </Layout>
  )
}

export default UserLayout
