import { createPhaseAction } from 'src/helpers/redux'
import { IGetAccounts } from 'src/services/accounts'

export const PREFIX = 'users'
export const SAGA = `${PREFIX}/saga`

import * as usersAuthService from 'src/services/auth/users'
import { IUserData } from 'src/services/auth/users'

export const actions = {
  getAccounts: createPhaseAction<IGetAccounts>(`${SAGA}/getAccounts`),

  getUsers: createPhaseAction<usersAuthService.IGetAllUsers>(
    `${SAGA}/getUsers`
  ),

  loadMoreUsers: createPhaseAction<usersAuthService.IGetAllUsers>(
    `${SAGA}/loadMoreUsers`
  ),

  getUsersOptions: createPhaseAction<usersAuthService.IGetAllUsers>(
    `${SAGA}/getUsersOptions`
  ),

  loadMoreUsersOptions: createPhaseAction<usersAuthService.IGetAllUsers>(
    `${SAGA}/loadMoreUsersOptions`
  ),

  getRoles: createPhaseAction(`${SAGA}/getRoles`),

  editUser: createPhaseAction<IUserData>(`${SAGA}/editUser`),

  inviteUsers: createPhaseAction<usersAuthService.IInviteUsers>(
    `${SAGA}/inviteUsers`
  ),
}

export type Actions = typeof actions
