import { http } from 'src/services/storage/project/http'
import * as types from './types'

export * from './types'

export const getFieldsGroups = (params: types.IGetFieldsGroupsParams) =>
  http.get<types.IFieldsGroupData>(`/scheme/${params.schemeName}/fields-groups`)

export const putFieldsGroups = ({
  schemeName,
  identifier,
  ...data
}: types.IPutFieldsGroupsParams) =>
  http.put<types.IFieldsGroupData>(
    `/scheme/${schemeName}/fields-groups/${identifier}`,
    data
  )
export const postFieldsGroups = ({
  schemeName,
  ...data
}: types.IPostFieldsGroupsParams) =>
  http.post<types.IFieldsGroupData>(`/scheme/${schemeName}/fields-groups`, data)
