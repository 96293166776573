import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'

const FallbackComponent = ({ error }: FallbackProps) => {
  const resetErrorBoundary = () => {
    window.location.reload()
  }

  return (
    <Stack
      direction="column"
      flex={1}
      sx={{ height: '100%' }}
      alignItems="center"
      justifyContent="center"
      p={2}
      spacing={3}
    >
      <Typography fontSize={12} fontWeight={600}>
        Something went wrong:
      </Typography>
      <Typography fontSize={12} fontWeight={600}>
        {error.message}
      </Typography>
      <Stack>
        <Button variant="contained" color="error" onClick={resetErrorBoundary}>
          Try again
        </Button>
      </Stack>
    </Stack>
  )
}

export default FallbackComponent
