import * as types from './types'
import { http } from 'src/services/storage/project/http'

export const getComments = (params: types.IGetObjectsComments) =>
  http.get(`/scheme/${params.schemeName}/objects/${params.uniqId}/comments`)

export const postComment = ({
  schemeName,
  uniqId,
  data,
}: types.IPostObjectComment) =>
  http.post(`/scheme/${schemeName}/objects/${uniqId}/comments`, {
    ...data,
  })

export const getAllComments = (params: types.IGetAllComments) =>
  http.get(`/comments`, {
    params: {
      ...params,
      assignedToMe: Number(params.assignedToMe),
      showResolved: Number(params.showResolved),
    },
  })

export const resolveComment = (params: types.IPostResolveComment) =>
  http.post(
    `/scheme/${params.schemeName}/objects/${params.uniqId}/comments/${params.commentId}/resolve`
  )
