/* eslint-disable @typescript-eslint/no-unused-vars */
import * as SchemeServiceTypes from 'src/services/storage/project/scheme/types'
import * as storageObjectsState from 'src/store/modules/storage/objects/state'
import { camelCase } from 'camel-case'
import {
  ISchema,
  CommunicationTypes,
  ISchemaChoice,
} from 'src/services/storage/project/scheme/types'
import { title } from 'process'
import { IColumn } from 'src/store/modules/storage/objects/state'
import { ISmartListUserOptions } from 'src/services/storage/project/scheme/smart-list'

export const getObjectValueKey = (schema: ISchema) =>
  camelCase(schema.identifier)

export const isSchemaLinkable = (schema: ISchema) =>
  (
    [
      CommunicationTypes.SITE,
      CommunicationTypes.FACEBOOK,
      CommunicationTypes.TWITTER,
      CommunicationTypes.LINKEDIN,
    ] as string[]
  ).includes(schema.communicationType || '')

export const isSchemaClalable = (schema: ISchema) =>
  (
    [
      CommunicationTypes.PHONE,
      CommunicationTypes.TELEGRAM,
      CommunicationTypes.VIBER,
      CommunicationTypes.WHATSAPP,
    ] as string[]
  ).includes(schema.communicationType || '')

export const getCommunicationTypeGeneral = (schema: ISchema) => {
  if (!schema.communicationType) return 'text'
  if (schema.communicationType === 'email') return 'email'
  if (isSchemaLinkable(schema)) return 'link'
  if (isSchemaClalable(schema)) return 'phone'

  return 'text'
}
export const createSchemeFieldTypeSlug = (
  schema: SchemeServiceTypes.ISchemaProps
): string =>
  [
    schema.valueType,
    schema.isMultivalue ? 'multivalue' : '',
    schema.visualStyle,
    schema.mediaType,
    schema.communicationType,
  ]
    .filter((type) => type)
    .join('.')

interface ISetupColumnsProps {
  schemaName: string
  fieldsGroups: SchemeServiceTypes.IFieldsGroupData[]
  schemas: SchemeServiceTypes.ISchemaInfo[]
  columns?: ISmartListUserOptions['columns']
}

export const setupColumns = (
  props: ISetupColumnsProps
): storageObjectsState.IColumn[] => {
  const { schemaName, fieldsGroups, schemas, columns } = props

  const baseRelation = schemas.find((scheme) =>
    scheme.children?.find?.((child) => child.identifier === schemaName)
  )
  const currentSchema = schemas.find(
    (scheme) => scheme.identifier === schemaName
  )

  const fields = fieldsGroups.reduce((previous, current) => {
    if (current.schemas) {
      previous.push(...current.schemas)
    }

    return previous
  }, [] as SchemeServiceTypes.ISchema[])

  const columnsSync = fields
    .map((schema) => {
      const column = columns?.find(
        (item) => item.identifier === schema.identifier
      )

      return {
        identifier: schema.identifier,
        slug: createSchemeFieldTypeSlug(schema),
        objectValueKey: getObjectValueKey(schema),
        enable: column ? column.enable : true,
        width: column ? column.width : 200,
        priority: {
          index: column?.index || schema.sortIndex,
          static: false,
        },
        schema,
      }
    })
    .sort((a, b) => a.priority.index - b.priority.index)

  const tableColumns: storageObjectsState.IColumn[] = []
  const schemaColumn = columns?.find((item) => item.identifier === schemaName)
  tableColumns.push({
    identifier: schemaName,
    slug: schemaName,
    objectValueKey: schemaName,
    //@ts-ignore
    title: currentSchema?.title || schemaName,
    width: schemaColumn?.width,
    enable: true,
    priority: {
      index: -10,
      static: true,
    },
  })

  if (baseRelation) {
    const parentObjectIdentifier = 'parentObjectUniqId'
    const columnParentObject = columns?.find(
      (item) => item.identifier === parentObjectIdentifier
    )
    tableColumns.push({
      identifier: parentObjectIdentifier,
      slug: parentObjectIdentifier,
      objectValueKey: parentObjectIdentifier,
      enable: columnParentObject ? columnParentObject?.enable : true,
      //@ts-ignore
      title: baseRelation?.title,
      schema: {
        identifier: parentObjectIdentifier,
        valueType: 'relationship',
        title: baseRelation?.title,
        visualStyle: 'dropdown',
        relationshipObjectSchemeId: baseRelation.id,
      } as SchemeServiceTypes.ISchema,
      priority: {
        index: columnParentObject ? columnParentObject?.index : -9,
        static: false,
      },
    })
  }

  const toBeSyncColumns = [...tableColumns, ...columnsSync]
    .sort((a, b) => a.priority.index - b.priority.index)
    .map((item, index) => ({ ...item, priority: { ...item.priority, index } }))

  return toBeSyncColumns
}

export interface IObjectBoardState {
  choice: ISchemaChoice
  enable: boolean
  index: number
}
export interface IObjectBoardField {
  identifier: string
  enable: boolean
  index: number
}
export interface IObjectBoard {
  identifier: string
  title: string
  stages: IObjectBoardState[]
  fields: IObjectBoardField[]
}
export interface IObjectBoardConfig {
  boards: IObjectBoard[]
}
interface ISetupBoardProps {
  schemaName: string
  fieldsGroups: SchemeServiceTypes.IFieldsGroupData[]
  schemas: SchemeServiceTypes.ISchemaInfo[]
  boardConfig?: IObjectBoardConfig
}
export const setupBoard = (props: ISetupBoardProps): IObjectBoardConfig => {
  const { schemaName, fieldsGroups, schemas, boardConfig } = props

  // const baseRelation = schemas.find((scheme) =>
  //   scheme.children?.find?.((child) => child.identifier === schemaName)
  // )
  // const currentSchema = schemas.find(
  //   (scheme) => scheme.identifier === schemaName
  // )

  const fields = fieldsGroups.reduce((previous, current) => {
    if (current.schemas) {
      previous.push(...current.schemas)
    }

    return previous
  }, [] as SchemeServiceTypes.ISchema[])

  const stagesFields = fields.filter((field) => field.visualStyle === 'stage')
  const getStage = (boardIdentifier: string, stageId: number) =>
    boardConfig?.boards
      ?.find((board) => board.identifier === boardIdentifier)
      ?.stages?.find((stage) => stage.choice.id === stageId)

  const getField = (boardIdentifier: string, fieldIdentifier: string) =>
    boardConfig?.boards
      ?.find((board) => board.identifier === boardIdentifier)
      ?.fields?.find((field) => field.identifier === fieldIdentifier)

  const board = stagesFields.reduce(
    (config, stageField) => ({
      ...config,
      boards: [
        ...config.boards,
        {
          identifier: stageField.identifier,
          title: stageField.title,
          stages:
            stageField.choices?.map((item, index) => {
              const stage = getStage(stageField.identifier, item.id)

              return {
                choice: item,
                enable: stage?.enable === undefined ? true : stage.enable,
                index: stage?.index === undefined ? item.index : stage.index,
              }
            }) || [],
          fields: fields
            .filter((item) => item.identifier !== stageField.identifier)
            .map((item) => {
              const field = getField(stageField.identifier, item.identifier)

              return {
                identifier: item.identifier,
                enable: field?.enable === undefined ? true : field.enable,
                index:
                  field?.index === undefined ? item.sortIndex : field?.index,
              }
            })
            .sort((a, b) => a.index - b.index),
        },
      ],
    }),
    { boards: [] } as IObjectBoardConfig
  )

  return board
}
