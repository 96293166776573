/* eslint-disable @typescript-eslint/no-empty-interface */
import { createPhaseActions, phaseAction } from 'src/helpers/redux'
import * as objectsServiceTypes from 'src/services/storage/project/scheme/objects/types'
import * as fieldsGroupServiceTypes from 'src/services/storage/project/scheme/fields-groups/types'
import * as smartListTypes from 'src/services/storage/project/scheme/smart-list/types'
import * as stageSortTypes from 'src/services/storage/project/scheme/objects/stage-sort/types'

import { IModuleConfig } from './index'
import { IUpdateObjectItem } from './state'

export interface ITableColumnsPriorityPayload {
  identifier: string
  index: number
}

export interface ITableColumnsEnablePayload {
  identifier: string
  enable: boolean
}
export interface ITableColumnsWidthPayload {
  identifier: string
  width: number
}

export interface IBoardStagePriorityPayload {
  boardIdentifier: string
  choiseId: number
  index: number
}
export interface IBoardStageEnablePayload {
  boardIdentifier: string
  choiseId: number
  enable: boolean
}

export interface IBoardFieldsPriorityPayload {
  boardIdentifier: string
  fieldIdentifier: string
  index: number
}

export interface IBoardFieldsEnablePayload {
  boardIdentifier: string
  fieldIdentifier: string
  enable: boolean
}

export type OmitSchemeName<I> = Omit<I, 'schemeName'>

export interface IGetObjectsListPayload
  extends Omit<objectsServiceTypes.IGetObjectsParams, 'schemeName'> {}
export interface IGetObjectsItemPayload
  extends Omit<objectsServiceTypes.IGetObjectsByIdParams, 'schemeName'> {}
export interface IPutObjectsItemPayload
  extends Omit<objectsServiceTypes.IPutObjectsParams, 'schemeName'> {}
export interface IPostObjectsItemPayload
  extends Omit<objectsServiceTypes.IPostObjectsParams, 'schemeName'> {}
export interface IGetFieldsGroupsPayload
  extends Omit<fieldsGroupServiceTypes.IGetFieldsGroupsParams, 'schemeName'> {}

interface IObjectActionSettings {
  fullfiledAction?: string | { type: string; payload: unknown }
  fullfiledActionContext?: Record<string, unknown>
}
export interface IObjectFullfiledActionPayload<
  TContext = Record<string, unknown>
> {
  data: objectsServiceTypes.IGetObjectsData
  context: TContext
}

export const createFullfiledAction = (
  type: string,
  payload: {
    data: IObjectFullfiledActionPayload['data']
    context?: IObjectFullfiledActionPayload['context']
  }
) => ({
  type,
  payload,
})

export const createActions = (config: IModuleConfig) => {
  const prefix = `${config.prefix}/sagas`

  return createPhaseActions(prefix, {
    getMoreObjectList: phaseAction<IGetObjectsListPayload>(),
    getObjectsList: phaseAction<IGetObjectsListPayload>(),
    getObjectsItem: phaseAction<IGetObjectsItemPayload>(),
    putObjectsItem: phaseAction<
      IPutObjectsItemPayload & IObjectActionSettings
    >(),
    postObjectsItem: phaseAction<
      IPostObjectsItemPayload & IObjectActionSettings
    >(),
    changeListItem: phaseAction<objectsServiceTypes.IGetObjectsData>(),
    changeObjectList: phaseAction<objectsServiceTypes.IGetObjectsData[]>(),
    updateObjectItem: phaseAction<IUpdateObjectItem>(),
    deleteObjectItem:
      phaseAction<objectsServiceTypes.IDeleteObjectByIdParams>(),

    getFieldsGroups: phaseAction<IGetFieldsGroupsPayload>(),

    setTableColumnsPriority: phaseAction<ITableColumnsPriorityPayload>(),
    setTableColumnsEnable: phaseAction<ITableColumnsEnablePayload>(),

    setBoardStagePriority: phaseAction<IBoardStagePriorityPayload>(),
    setBoardStageEnable: phaseAction<IBoardStageEnablePayload>(),
    setBoardFieldsPriority: phaseAction<IBoardFieldsPriorityPayload>(),
    setBoardFieldsEnable: phaseAction<IBoardFieldsEnablePayload>(),
    setBoardCardUpdateFulfiled: phaseAction<IObjectFullfiledActionPayload>(),
    setBoardCardCreateFulfiled: phaseAction<IObjectFullfiledActionPayload>(),

    setTableColumnsWidth: phaseAction<ITableColumnsWidthPayload>(),
    saveTableColumns: phaseAction<void>(),
    syncTableColumns: phaseAction<void>(),
    getSmartList:
      phaseAction<OmitSchemeName<smartListTypes.IGetSmartListParams>>(),
    getSmartListItem:
      phaseAction<OmitSchemeName<smartListTypes.IGetSmartListItemParams>>(),
    getSmartListItemDefault:
      phaseAction<
        OmitSchemeName<smartListTypes.IGetSmartListItemDefaultParams>
      >(),
    setSmartListItemDefault: phaseAction<smartListTypes.ISmartList>(),

    setSmartListItem: phaseAction<smartListTypes.ISmartList>(),

    putSmartList:
      phaseAction<OmitSchemeName<smartListTypes.IPutSmartListParams>>(),

    postSmartList:
      phaseAction<OmitSchemeName<smartListTypes.IPostSmartListParams>>(),

    postStageSortStart: phaseAction<stageSortTypes.IStageSortBaseUrlParams>(),
    postStageSortEnd: phaseAction<stageSortTypes.IStageSortBaseUrlParams>(),
    postStageSortBefore: phaseAction<stageSortTypes.IStageSortTargetParams>(),
    postStageSortAfter: phaseAction<stageSortTypes.IStageSortTargetParams>(),

    deleteSmartList:
      phaseAction<OmitSchemeName<smartListTypes.IDeleteSmartListParams>>(),
    logging: phaseAction<void>(),
    cacheStorage: phaseAction<void>(),
  })
}

export type Actions = ReturnType<typeof createActions>
