/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, delay } from 'redux-saga/effects'
import { createSagas } from 'src/helpers/redux'

import { IResponse } from 'src/services/mail/http'
import * as schemaService from 'src/services/storage/project/scheme'

import { setters } from './index'
import { actions, Actions } from './actions'

import { IState } from './state'

export default createSagas<Actions>(actions, {
  getGlobalSearch: {
    effect: 'latest',
    saga: function* ({ payload }) {
      yield delay(1000)
      const searchResult: IResponse<IState['globalSearchObject']> = yield call(
        schemaService.globalSearch,
        {
          ...payload,
        }
      )

      yield put(setters.setGlobalSearch(searchResult.data))
    },
  },
})
