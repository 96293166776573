export enum CallStatuses {
  OUTGOING = 'outgoing',
  INCOMING = 'incoming',
  MISSED = 'missed',
}

export interface IToWhomCall {
  number: string
  name?: string
  secondary?: string
  avatarUrl?: string
  from?: string
  uniqId?: string
  scheme?: string
}

export interface IOutgoingCall {
  userId?: string
  toWhom: IToWhomCall
}

export enum ToolConfiguration {
  CALL_MANAGER = 'CALL_MANAGER',
  ADD = 'ADD',
  DIALPLADE = 'DIALPLADE',
  HISTORY = 'HISTORY',
  TRANSFER = 'TRANSFER',
  CONTACTS = 'CONTACTS',
  NONE = 'NONE',
}

export enum CallState {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  ANSWERED = 'ANSWERED',
}

export interface ICall {
  id: number
  accountId: number
  externalId: null
  subscriberId: number
  type: CallStatuses
  status: string
  syncAt: string
  duration: string | null
  createdAt: string
  updatedAt: string

  subscriber: {
    identifier: string
    storageObject: {
      uniqId: string
      objectSchemeIdentifier: string
      primary: string
      secondary: string
      icon: string
      parentObjectUniqId: string
    }
  }

  account: {
    id: number
    user_id: number
    identifier: string
    internal_id: number
    driver: string
    status: string
    created_at: string
    updated_at: string
    number: {
      id: 1
      partner_global_id: null
      driver: string
      region_code: string
      phone_number: string
      display_name: string
      active: true
      sync_at: string
      created_at: string
      updated_at: string
    }
    userIdentifier?: {
      info?: {
        avatar: string
        firstName: string
        jobTitle: string
        lastName: string
      }
    }
  }
}
