export enum Type {
  LINK = 'LINK',
  DIVIDER = 'DIVIVDER',
  CALLBACK = 'CALLBACK',
}

export const navigationConfig = [
  {
    id: 1,
    text: 'My profile',
    icon: 'tabler:user-check',
    path: '/profile',
  },

  {
    id: 2,
    text: 'Settings',
    icon: 'tabler:settings',
    path: '/settings',
  },

  {
    id: 3,
    text: 'Billing',
    icon: 'tabler:credit-card',
    path: '/',
  },

  // { id: 5, type: Type.DIVIDER },
  // { id: 6, text: 'Help', icon: 'tabler:lifebuoy', path: '/help' },
  //   { id: 7, text: 'FAQ', icon: 'tabler:info-circle', path: '/' },
  // { id: 8, text: 'Pricing', icon: 'tabler:currency-dollar', path: '/' },
  { id: 9, type: Type.DIVIDER },
  { id: 10, text: 'Sign out', icon: 'tabler:logout', type: Type.CALLBACK },
]
