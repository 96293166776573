import { createHooksActions, createHooksSelectors } from 'src/helpers/redux'
import * as store from 'src/store/modules/app'

export { store }

export const useAppSelect = createHooksSelectors<
  typeof store.selectors.selectors
>(store.selectors.selectors)

export const useAppAction = createHooksActions<typeof store.actions>(
  store.actions,
  store.selectors.communications
)
