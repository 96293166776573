import { http } from 'src/services/auth/http'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGetBillingStatParams {}
export interface IGetBillingStatData {
  crmPrice: number
  communicationPhoneNumbersPrice: number
  communicationEmailsPrice: number
  communicationSmsPrice: number
  communicationCallsPrice: number
}

export const getBillingStat = (parmas?: IGetBillingStatParams) =>
  http.get<IGetBillingStatData>('/partner/billing-stat', parmas)
