/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useSearchQuery } from 'src/hooks/useSearchQuery'
import { useAppSelect } from 'src/hooks/useApp'

export const ReactRouterRedux = (props: { children: React.ReactNode }) => {
  const { location, setPathQuery, setQuery } = useSearchQuery()
  const appLocation = useAppSelect.location()

  // React.useEffect(()=>{
  //   if (appLocation.key !== location.key){
  //     appNavigate({
  //       key: location.key,
  //       path: location.pathname,
  //       search: query,
  //     })
  //   }
  // },[location.key, query])

  React.useEffect(() => {
    if (appLocation.key && appLocation.key !== location.key) {
      if (appLocation.path) {
        setPathQuery(
          appLocation.path,
          appLocation.search || {},
          appLocation.replaced
        )
      } else {
        setQuery(appLocation.search || {}, appLocation.replaced)
      }
    }
  }, [appLocation.key])

  return <>{props.children}</>
}
