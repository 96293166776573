import { createSelector } from 'reselect'
import { createCommunicationSelectors } from 'src/helpers/redux'
import { IState, PREFIX, TGlobalStateWithService } from './state'
import { actions } from './actions'
import { ECommState } from 'src/types/redux'

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions)

export const selectors = {
  users: createSelector(selectData, (state) => state.users),
  isUsersEmpty: createSelector(
    [selectData, selectCommunication],
    (state, status) =>
      state.users.items.length === 0 &&
      status.getUsers.state === ECommState.fulfilled
  ),
  isUsersNextLoad: createSelector(
    [selectData, selectCommunication],
    (state, status) =>
      state.users.nextCursor && status.getUsers.state === ECommState.fulfilled
  ),

  usersOptions: createSelector(selectData, (state) => state.usersOptions),
  isUsersOptionsEmpty: createSelector(
    [selectData, selectCommunication],
    (state, status) =>
      state.usersOptions.items.length === 0 &&
      status.getUsersOptions.state === ECommState.fulfilled
  ),
  isUsersOptionsNextLoad: createSelector(
    [selectData, selectCommunication],
    (state, status) =>
      state.usersOptions.nextCursor &&
      status.getUsersOptions.state === ECommState.fulfilled
  ),

  roles: createSelector(selectData, (state) => state.roles),
  targetedAccount: createSelector(selectData, (state) => state.accounts),
}
