import { createCommunicationSelectors } from 'src/helpers/redux'
import { IState, PREFIX, TGlobalStateWithService } from './state'
import { createSelector } from 'reselect'
import { actions } from './actions'

export const selectCallHistory = (
  state: TGlobalStateWithService
): IState['data']['callHistory'] => state[PREFIX].data.callHistory

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data

export const store = (state: TGlobalStateWithService): IState => state[PREFIX]
export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions)

/// new selectors

export const selectWidgetOptions = (
  state: TGlobalStateWithService
): IState['widgetOptions'] => state[PREFIX].widgetOptions

export const selectWidgetData = (
  state: TGlobalStateWithService
): IState['widgetOptions'] => state[PREFIX].widgetOptions

export const selectors = {
  twillioEnabled: createSelector(store, (state) => state.enabled),
  widgetOptions: createSelector(selectWidgetOptions, (state) => state),
  selectWidgetData: createSelector(selectWidgetData, (state) => state),
  storeData: createSelector(selectData, (state) => state),
}
