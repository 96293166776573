import axios, { AxiosPromise } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import {
  AuthInterceptorRequestFullfiled,
  DataFormInterceptorRequestFullfiled,
  DataFormInterceptorMetodKey,
} from 'src/services/interceptors/request'
import {
  INormalizeResponse,
  NormalizeInterceptorResponseFullfiled,
  ErrorProcessing,
} from 'src/services/interceptors/response'
import * as locationService from 'src/services/location'

export type {
  IValidationErrorsData,
  IValidationMultiplyErrorsData,
} from 'src/services/interceptors/response'

const partner = locationService.getPartner()
const project = locationService.getProject()
const domain = window.settings.BASE_STORAGE_DOMAIN

export const baseURL = `//${partner}.${domain}/api/project/${project}`
export const baseURLV2 = `//${partner}.${domain}/api/v2/project/${project}`
export type IResponse<data> = INormalizeResponse<data>
export type HTTPPromise<Data> = AxiosPromise<Data>

export const http = axios.create({
  baseURL,
  withCredentials: false,
})

export const apiV2 = axios.create({
  baseURL: baseURLV2,
  withCredentials: false,
})

applyCaseMiddleware(http, {
  preservedKeys: (input) => {
    return [DataFormInterceptorMetodKey].includes(input)
  },
})
http.interceptors.request.use(DataFormInterceptorRequestFullfiled)
http.interceptors.response.use(
  NormalizeInterceptorResponseFullfiled,
  ErrorProcessing
)
http.interceptors.request.use(AuthInterceptorRequestFullfiled)

applyCaseMiddleware(apiV2, {
  preservedKeys: (input) => {
    if (input.includes('.')) {
      return true
    }

    return [DataFormInterceptorMetodKey].includes(input)
  },
})
apiV2.interceptors.request.use(DataFormInterceptorRequestFullfiled)
apiV2.interceptors.response.use(
  NormalizeInterceptorResponseFullfiled,
  ErrorProcessing
)
apiV2.interceptors.request.use(AuthInterceptorRequestFullfiled)
