import { createSelector } from 'reselect'
import { createCommunicationSelectors } from 'src/helpers/redux'
import { IState, PREFIX, TGlobalStateWithService } from './state'
import { actions } from './actions'
import {
  getAllGroupsPermissionsStructure,
  getLocalPermissions,
} from 'src/utils/permissions'
import deepMerge from 'deepmerge'

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data

export const connectedAccounts = (
  state: TGlobalStateWithService
): IState['accounts'] => state[PREFIX].accounts

export const allUsersSelector = (
  state: TGlobalStateWithService
): IState['allUsers'] => state[PREFIX].allUsers

export const rolesSelector = (
  state: TGlobalStateWithService
): IState['roles'] => state[PREFIX].roles

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions)

const selectUser = createSelector(selectData, (state) => state.user)

export const selectors = {
  isAfterLogin: createSelector(selectData, (state) =>
    Boolean(state.isAfterLogin)
  ),
  isGuest: createSelector(selectData, (state) => !Boolean(state.accessToken)),
  isUser: createSelector(selectData, (state) => Boolean(state.accessToken)),
  isAdmin: createSelector(selectUser, (user) =>
    Boolean(user?.groups.find((group) => group.slug === 'admin'))
  ),

  permissions: createSelector(selectUser, (user) => {
    const groups = user?.groups.map((group) => group.slug) || []

    const permissions = getAllGroupsPermissionsStructure(user?.groups || [])
    const localPermissions = getLocalPermissions(groups)

    return deepMerge(permissions, localPermissions)
  }),

  roles: createSelector(rolesSelector, (state) => state),

  user: createSelector(selectData, (state) => state.user),
  userData: createSelector(selectData, (state) => state),
  allUsersSelector: createSelector(allUsersSelector, (state) => state),
  //TO DO ETO KOSTIL'
  targetedAccount: createSelector(connectedAccounts, (state) => state),
  billingStat: createSelector(selectData, (state) => state.billingStat),
}
