import Cookies from 'js-cookie'
import * as usersAuthService from './auth/users'
import { getProject, getPartner, isDevelopment } from './location'
import { ISchemaInfo } from './storage/project/scheme'
import { IModuleConfig, IStateData } from 'src/store/modules/storage/objects'

const SESSION_KEY = `cusman_${getPartner()}_${getProject()}`
const cookieService = Cookies.withAttributes({
  domain: isDevelopment ? undefined : window.location.hostname,
})

export interface IStorageItem extends IModuleConfig {
  smartListsItems: IStateData['smartList']['list']['items']
  objectItems: IStateData['objects']['list']['items']
  fieldsGroupsItems: IStateData['fieldsGroups']['list']['items']
  smartListItemDefault: IStateData['smartList']['itemDefault']
  table: IStateData['table']
  board: IStateData['board']
}

export interface ISession {
  accessToken?: usersAuthService.ILoginData['accessToken']
  expiresIn?: usersAuthService.ILoginData['expiresIn']
  tokenType?: usersAuthService.ILoginData['tokenType']
  userId?: number
  user?: usersAuthService.IGetAccountData
  schemas?: ISchemaInfo[]
  storage?: IStorageItem[]
}

export const getInitialSession = (): ISession => {
  return {
    accessToken: undefined,
    expiresIn: undefined,
    tokenType: undefined,
    userId: undefined,
    user: undefined,
    storage: [],
  }
}

export const getSession = (): ISession => {
  const sessionData = cookieService.get(SESSION_KEY)

  if (!sessionData) {
    return getInitialSession()
  }

  try {
    const session = JSON.parse(sessionData)

    return {
      ...getInitialSession(),
      ...session,
    }
  } catch (e) {
    console.error('invalid session')

    return getInitialSession()
  }
}

export const setSession = (session: ISession) => {
  try {
    const prevSession = getSession() || getInitialSession()
    const toUpdatedSession = { ...prevSession, ...session }
    cookieService.set(SESSION_KEY, JSON.stringify(toUpdatedSession), {
      expires: 365, //days
    })
  } catch (e) {
    console.error(e)
  }
}
