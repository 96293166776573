/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense } from 'react'
import { createBrowserRouter, Location } from 'react-router-dom'
import UserLayout from 'src/layouts/UserLayout'
import { ReactRouterRedux } from 'src/components/ReactRouterRedux'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { usePermission, PermissionPath } from 'src/hooks/usePermission'

const pagesCache = new Map<string, React.ComponentType<any>>()

const load = (props: { page: string; permission?: PermissionPath }) => () => {
  const { permission } = props
  const isAccess = usePermission(permission)
  const page = isAccess ? props.page : 'access-denied'

  const PageCache = pagesCache.get(page)

  if (PageCache) {
    return <PageCache />
  } else {
    const Page = React.lazy(() =>
      import(`src/pages/${page}`).then((pageModule) => {
        pagesCache.set(page, pageModule.default)

        return pageModule
      })
    )

    return (
      <Suspense
        fallback={
          <Stack
            sx={{
              flex: 1,
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Stack>
        }
      >
        <Page />
      </Suspense>
    )
  }
}

export const router = createBrowserRouter([
  {
    element: (
      <ReactRouterRedux>
        <UserLayout contentHeightFixed />
      </ReactRouterRedux>
    ),
    children: [
      {
        path: '',
        Component: load({
          page: 'dashboard',
          permission: undefined,
        }),
      },
      {
        path: 'callHistory',
        Component: load({
          page: 'callHistory',
          permission: undefined,
        }),
      },
      {
        path: 'callHistory',
        Component: load({
          page: 'callHistory',
          permission: undefined,
        }),
      },
      {
        path: 'profile',
        Component: load({
          page: 'profile',
        }),
      },

      {
        path: 'storage/:schemeName',
        Component: load({
          page: 'storage/object',
          permission: 'storage.objects.index',
        }),
      },
      {
        path: 'storage/:schemeName/:objectId',
        Component: load({
          page: 'storage/object/id',
          permission: 'storage.objects.index',
        }),
      },
      {
        path: 'storage/:schemeName/:objectId/details',
        Component: load({
          page: 'storage/object/id/details',
          permission: undefined,
        }),
        children: [
          {
            path: '',
            Component: load({
              page: 'storage/object/id/details/dashboard',
              permission: undefined,
            }),
          },
          {
            path: 'dashboard',
            Component: load({
              page: 'storage/object/id/details/dashboard',
              permission: undefined,
            }),
          },
          {
            path: 'trends',
            Component: load({
              page: 'storage/object/id/details/trends',
              permission: undefined,
            }),
          },
          {
            path: 'events',
            Component: load({
              page: 'storage/object/id/details/events',
              permission: undefined,
            }),
          },
          {
            path: 'elements',
            Component: load({
              page: 'storage/object/id/details/elements',
              permission: undefined,
            }),
          },
          {
            path: 'contacts',
            Component: load({
              page: 'storage/object/id/details/contacts',
              permission: undefined,
            }),
          },
          {
            path: 'nps',
            Component: load({
              page: 'storage/object/id/details/nps',
              permission: undefined,
            }),
          },
          {
            path: 'settings',
            Component: load({
              page: 'storage/object/id/details/settings',
              permission: undefined,
            }),
          },
          {
            path: 'touch-points',
            Component: load({
              page: 'storage/object/id/details/touch-points',
              permission: undefined,
            }),
          },
          {
            path: 'tasks',
            Component: load({
              page: 'storage/object/id/details/tasks',
              permission: undefined,
            }),
          },
          {
            path: 'notes',
            Component: load({
              page: 'storage/object/id/details/notes',
              permission: undefined,
            }),
          },
          {
            path: 'conversations',
            Component: load({
              page: 'storage/object/id/details/conversations',
              permission: undefined,
            }),
          },
          {
            path: 'emails',
            Component: load({
              page: 'storage/object/id/details/emails',
              permission: undefined,
            }),
          },
          {
            path: 'attachments',
            Component: load({
              page: 'storage/object/id/details/attachments',
              permission: undefined,
            }),
          },
          {
            path: 'play-books',
            Component: load({
              page: 'storage/object/id/details/play-books',
              permission: undefined,
            }),
          },
        ],
      },

      {
        path: 'tasks',
        Component: load({
          page: 'tasks',
          permission: 'tasks.index',
        }),
      },
      {
        path: 'health-score',
        Component: load({
          page: 'healthScore',
          permission: undefined,
        }),
      },

      {
        path: 'email',
        Component: load({
          page: 'email',
          permission: 'mail.thred.index',
        }),
      },
      {
        path: 'playbooks',
        Component: load({
          page: 'playbooks',
          permission: 'playbooks.index',
        }),
      },
      {
        path: 'templates',
        children: [
          {
            path: ':type',
            Component: load({
              page: 'templates',
              permission: 'templates.index',
            }),
          },
        ],
      },
      {
        path: 'numbers',
        Component: load({
          page: 'admin/numbers',
          permission: 'communication.twilio.index',
        }), // need transfer to folder load/numbers
      },
      {
        path: 'billing-history',
        Component: load({
          page: 'billing-history',
        }),
      },
      {
        path: 'tg-accounts',
        Component: load({
          page: 'admin/tgNumbers',
          permission: 'communication.telegram.index',
        }), // need transfer to folder load/tgNumbers
      },
      {
        path: 'admin',
        children: [
          {
            path: 'fields/:schemeName',
            Component: load({
              page: 'admin/fields/schemeName',
              permission: 'storage.fieldsScheme.index',
            }),
          },

          {
            path: 'groups/:schemeName',
            Component: load({
              page: 'admin/groups/schemeName',
              permission: 'storage.fieldsGroup.index',
            }),
          },

          {
            path: 'users',
            Component: load({
              page: 'admin/users',
              permission: 'auth.users.index',
            }),
          },
          {
            path: 'integration',
            Component: load({
              page: 'admin/integration',
              // permission: 'auth.users.index',
            }),
          },
          {
            path: 'users-groups',
            Component: load({
              page: 'admin/users-groups',
              permission: 'auth.groups.index',
            }),
          },
          {
            path: 'api-keys',
            Component: load({
              page: 'admin/api-keys',
              permission: 'auth.users.index',
            }),
          },
          {
            path: 'schemes',
            Component: load({
              page: 'admin/schemes',
              permission: 'storage.objectScheme.index',
            }),
          },
        ],
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: '*',
        Component: load({
          page: '404',
          permission: undefined,
        }),
      },
    ],
  },
  {
    path: 'login',
    Component: load({
      page: 'login',
      permission: undefined,
    }),
  },
])

export interface RouterHistory {
  locations: Location[]
  getPrevLocation: () => Location | null
}
export const routerHistory: RouterHistory = {
  locations: [],
  getPrevLocation: () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [current, prev] = routerHistory.locations

    return prev
  },
}

router.subscribe(({ location }) => {
  const [prev] = routerHistory.locations

  const isNotEqual =
    prev &&
    // prev.hash !== location.hash ||
    prev.pathname !== location.pathname
  // prev.search !== location.search ||
  // prev.state !== location.state

  if (prev && isNotEqual) {
    routerHistory.locations = [{ ...location }, ...routerHistory.locations]
  }
  if (!prev) {
    routerHistory.locations = [{ ...location }]
  }
  if (routerHistory.locations.length > 5) {
    routerHistory.locations = routerHistory.locations.slice(0, 5)
  }
})

export default router
