export const isDevelopment = window.location.hostname.includes('localhost')

export const getProject = () => {
  return 'default_project'
}

export const getPartner = () => {
  if (isDevelopment) {
    return window.settings.APP_PARNER || 'owiks'
  }

  const [partner] = window.location.hostname.split('.')

  return partner
}
