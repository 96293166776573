import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'

import store from 'src/store'
import router from 'src/router'
import ThemeComponent from 'src/theme/ThemeComponent'
import { SettingsConsumer, SettingsProvider } from 'src/context/settingsContext'
import ReactHotToast from 'src/styles/libs/react-hot-toast'

import 'src/services/sentry'
import 'src/locale/i18n'

import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'src/styles/globals.css'
import 'reactflow/dist/style.css'

const App = () => {
  return (
    <Provider store={store}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <RouterProvider router={router} />

                <ReactHotToast>
                  <Toaster
                    position={settings.toastPosition}
                    toastOptions={{
                      className: 'react-hot-toast',
                      duration: 1000 * 5,
                    }}
                  />
                </ReactHotToast>
              </ThemeComponent>
            )
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </Provider>
  )
}

export default App
