import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import store from 'src/store'
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux'
import { actions as moduleActions } from './actions'
import * as moduleState from './state'
import moduleSagas from './sagas'
import * as moduleSelectors from './selectors'
import { nanoid } from 'nanoid'

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setSchemas(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['schemas']>
    ) {
      state.data.schemas = action.payload
    },
    setSchemasAppend(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['schemas'][number]>
    ) {
      state.data.schemas.push(action.payload)
    },
    setSchemasUpdate(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['schemas'][number]>
    ) {
      state.data.schemas = state.data.schemas.map((item) => {
        if (item.identifier === action.payload.identifier) {
          return action.payload
        }

        return item
      })
    },
    setSchemasDelete(
      state: moduleState.IState,
      action: PayloadAction<{ identifier: string }>
    ) {
      state.data.schemas = state.data.schemas.filter(
        (item) => item.identifier !== action.payload.identifier
      )
    },
    setToastId(
      state: moduleState.IState,
      action: PayloadAction<{ key: string; id?: string | number }>
    ) {
      const { key, id } = action.payload

      if (id) {
        state.data.toasts[key] = id
      } else {
        delete state.data.toasts[key]
      }
    },
    setFieldsGroups(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['fieldsGroups'][string]>
    ) {
      state.data.fieldsGroups[action.payload.schemaName] = action.payload
    },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData()
      clearAllStateCommunications(state.communication)
    },
    setLocation(
      state: moduleState.IState,
      action: PayloadAction<{
        path?: string
        search?: Record<string, any>
        key?: string
        replaced?: boolean
      }>
    ) {
      // if (action.payload.key){
      state.data.location = { ...action.payload, key: nanoid() }
      // } else {
      //   state.data.location = {
      //     ...state.data.location,
      //     ...action.payload,
      //     search: {
      //       ...decodeSearch(),
      //       ...(action.payload.search || {})
      //     },
      //     replaced: action.payload.replaced || false,
      //     key: nanoid()
      //   }
      // }
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions)
  },
})

export const setters = slice.actions
export const actions = moduleActions
export const reducers = { [moduleState.PREFIX]: slice.reducer }
export const sagas = moduleSagas
export const state = moduleState
export const selectors = moduleSelectors

export const injectStore = () => {
  store.injectReducer?.(moduleState.PREFIX, slice.reducer)
  store.injectSaga?.(moduleState.PREFIX, sagas)
}
