import { LayoutProps } from 'src/layouts/types'
import VerticalLayout from './VerticalLayout'

const Layout = (props: LayoutProps) => {
  const { children } = props

  return <VerticalLayout {...props}>{children}</VerticalLayout>
}

export default Layout
