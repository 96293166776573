import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import * as moduleState from './state'
import store from 'src/store'
import * as moduleSelectors from './selectors'
import { actions as moduleActions } from './actions'
import moduleSagas from './sagas'

import { builderSagaCommunications } from 'src/helpers/redux'

export const globalSearchSlice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setGlobalSearch(
      state,
      action: PayloadAction<moduleState.IState['globalSearchObject']>
    ) {
      state.globalSearchObject = action.payload
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions)
  },
})

export const reducers = { [moduleState.PREFIX]: globalSearchSlice.reducer }
export const selectors = moduleSelectors
export const setters = globalSearchSlice.actions
export const actions = moduleActions
export const sagas = moduleSagas

export const injectStore = () => {
  store.injectReducer?.(moduleState.PREFIX, globalSearchSlice.reducer)
  store.injectSaga?.(moduleState.PREFIX, sagas)
}
