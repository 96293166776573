// import { IGetConnectedMails, IGetMessagesParams } from 'src/services/mail'
// import { ActiveTabConfig, ConfigStatesMail, IMessageModified } from './state'
import { createPhaseActions, phaseAction } from 'src/helpers/redux'

export const PREFIX = 'twilio'
export const PREFIX_SAGE = `${PREFIX}/saga`

export const actions = createPhaseActions(PREFIX_SAGE, {
  setupClient: phaseAction<void>(),
})
export type Actions = typeof actions
