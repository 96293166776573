import { IGlobalStore } from 'src/types/store'
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'
import { actions as sagaActions, PREFIX } from './actions'

export interface IState {
  communication: TStateCommunications<typeof sagaActions>
  data: {
    token?: string
  }
}

export const initialState: IState = {
  communication: createStateSagaCommunications(sagaActions),
  data: {
    token: undefined,
  },
}

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState
}

export { PREFIX }
