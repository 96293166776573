import { InternalAxiosRequestConfig } from 'axios'
import { getSession } from '../session'
import jsonToFormData from 'json-form-data'

export const AuthInterceptorRequestFullfiled = (
  config: InternalAxiosRequestConfig
) => {
  const session = getSession()

  config.headers = config.headers || {}

  if (session?.accessToken) {
    config.headers['Authorization'] = `Bearer ${session.accessToken}`
  }

  return config
}

export const DataFormInterceptorMetodKey = '_method'
const recursiveFindFiles = (
  data: object | string | Array<unknown> | Blob | File
): boolean => {
  //@ts-ignore
  const isBlob = data instanceof Blob
  //@ts-ignore
  const isFile = data instanceof File
  const isFileObject = isBlob || isFile

  if (isFileObject) {
    return true
  }
  if (Array.isArray(data)) {
    return Boolean(data.find(recursiveFindFiles))
  }

  if (data !== null && typeof data === 'object') {
    //@ts-ignore
    return Boolean(Object.keys(data).find((k) => recursiveFindFiles(data[k])))
  }

  return false
}
export const DataFormInterceptorRequestFullfiled = (
  config: InternalAxiosRequestConfig
) => {
  config.headers = config.headers || {}
  const dataFormMethods = ['POST', 'PUT', 'PATCH']
  const isMethodDataForm = dataFormMethods.includes(
    config.method?.toUpperCase() as string
  )

  if (isMethodDataForm) {
    if (config.data) {
      const hasPropertyFile = recursiveFindFiles(config.data)

      if (hasPropertyFile) {
        const formData = jsonToFormData(config.data, {
          initialFormData: new FormData(),
          showLeafArrayIndexes: true,
          includeNullValues: false,
          // mapping: function(value) {
          //     if (typeof value === 'boolean') {
          //         return +value ? '1': '0';
          //     }
          //     return value;
          // }
        })

        formData.append(
          DataFormInterceptorMetodKey,
          config.method?.toLowerCase() as string
        )
        config.method = 'POST'
        config.data = formData
        config.headers['Content-Type'] = 'multipart/form-data'
      }
    }
  }

  return config
}
