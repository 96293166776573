import React from 'react'
import { useAppAction } from './useApp'

export const useOnlineState = ({ showToast }: { showToast?: boolean }) => {
  const [online, setOnline] = React.useState(true)
  const toast = useAppAction.tost()

  const handleOnline = () => {
    setOnline(true)
  }

  const handleOffline = () => {
    setOnline(false)
    if (showToast) {
      toast({
        type: 'error',
        title: 'Connection lost',
        description: 'Check your internet connection.',
      })
    }
  }

  React.useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  return online
}
