import { IPermissions, IAccountGroup } from 'src/services/auth/users'
import setValue from 'set-value'
import { camelCase, kebabCase } from 'change-case'
import { flatten } from 'flat'

export const getPermissionsFlatten = (permissions: IPermissions): string[] => {
  const flat: Record<string, boolean> = flatten(permissions)

  return Object.keys(flat)
    .filter((rule) => flat[rule] === true)
    .map((rule) =>
      rule
        .split('.')
        .map((route) => kebabCase(route))
        .join('.')
    )
}

export const getPermissionStructure = (permissions: string[]): IPermissions => {
  return permissions.reduce((groupPermissions, permission) => {
    const rule = permission
      .split('.')
      .map((key) => camelCase(key))
      .join('.')

    setValue(groupPermissions, rule, true)

    return groupPermissions
  }, {} as IPermissions)
}

export const getAllGroupsPermissionsStructure = (
  groups: IAccountGroup[]
): IPermissions => {
  return (
    groups.reduce((permissions, group) => {
      const groupPermission = getPermissionStructure(group.permissions)

      return {
        ...permissions,
        ...groupPermission,
      }
    }, {} as IPermissions) || ({} as IPermissions)
  )
}

export const getLocalPermissions = (groups: string[]) => {
  const permissions: IPermissions = {}

  const allAccess = { index: true, update: true, store: true, destroy: true }
  const allAccessWithoutRemove = {
    index: true,
    update: true,
    store: true,
    destroy: false,
  }

  if (groups?.includes('admin') || groups?.includes('supervisor')) {
    setValue(permissions, 'drive.files', {
      ...allAccessWithoutRemove,
      primary: true,
      template: true,
    })

    setValue(permissions, 'storage.objectScheme', allAccess)
    setValue(permissions, 'storage.fieldsGroup', allAccess)
    setValue(permissions, 'storage.fieldsScheme', allAccess)
    setValue(permissions, 'storage.objects', allAccess)

    setValue(permissions, 'storage.comments.resolve', true)
    setValue(permissions, 'storage.smartLists', allAccess)

    setValue(permissions, 'templates', allAccess)
    setValue(permissions, 'communication.telegram', allAccess)
    setValue(permissions, 'communication.twilio', allAccess)
    setValue(permissions, 'communication.accounts', allAccess)

    setValue(permissions, 'mail.label', allAccess)
    setValue(permissions, 'mail.thred', allAccess)
    setValue(permissions, 'mail.accounts', allAccess)
  } else {
    setValue(permissions, 'drive.files', {
      ...allAccessWithoutRemove,
      primary: true,
      template: true,
    })
    setValue(permissions, 'storage.comments.resolve', true)
    setValue(permissions, 'storage.smartLists', allAccessWithoutRemove)
    setValue(permissions, 'storage.objectScheme', allAccessWithoutRemove)
    setValue(permissions, 'storage.fieldsGroup', allAccessWithoutRemove)
    setValue(permissions, 'storage.fieldsScheme', allAccessWithoutRemove)
    setValue(permissions, 'storage.objects', allAccess)
    setValue(permissions, 'mail.accounts', allAccessWithoutRemove)

    setValue(permissions, 'templates', allAccessWithoutRemove)
    setValue(permissions, 'communication.telegram', allAccessWithoutRemove)
    setValue(permissions, 'communication.twilio', allAccessWithoutRemove)
    setValue(permissions, 'communication.accounts', allAccessWithoutRemove)

    setValue(permissions, 'mail.label', allAccessWithoutRemove)
    setValue(permissions, 'mail.thred', allAccessWithoutRemove)
    setValue(permissions, 'mail.accounts', allAccessWithoutRemove)
  }

  return permissions
}
