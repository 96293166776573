import * as Sentry from '@sentry/react'
import { ListenerMiddleware } from '@reduxjs/toolkit'

interface IAction {
  type: string
  payload: {
    [key: string]: unknown
  }
}

export function createSentryMiddleware(): ListenerMiddleware {
  const isNotRejectedActions: string[] = []

  return (store) => {
    let lastAction: IAction | null = null

    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event) => {
        const state = store.getState()

        event.extra = {
          ...event.extra,
          lastAction: lastAction,
          state: state,
        }
        //@ts-ignore
        if (state?.account?.data) {
          //@ts-ignore
          event.user = { ...event.user, ...state.account.data }
        }

        return event
      })
    })

    return (next: (action: IAction) => void) => (action: IAction) => {
      const returnedValue = next(action)

      const isRejected =
        !isNotRejectedActions.includes(action.type) &&
        action.type.includes('/rejected')
      if (isRejected) {
        Sentry.addBreadcrumb({
          category: 'redux-rejected',
          message: action.type,
          level: 'error',
          data: action.payload,
        })
        Sentry.captureException(action.payload)
        lastAction = action
      }

      return returnedValue
    }
  }
}
