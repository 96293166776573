import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import store from 'src/store'
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux'
import { actions as moduleActions } from './actions'
import * as moduleState from './state'
import moduleSagas from './sagas'
import * as moduleSelectors from './selectors'

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setUsers(state, action: PayloadAction<moduleState.IStateData['users']>) {
      state.data.users = action.payload
    },

    setUsersAppend(
      state,
      action: PayloadAction<moduleState.IStateData['users']>
    ) {
      state.data.users = {
        items: [...state.data.users.items, ...action.payload.items],
        nextCursor: action.payload.nextCursor,
      }
    },

    setUsersOptions(
      state,
      action: PayloadAction<moduleState.IStateData['users']>
    ) {
      state.data.usersOptions = action.payload
    },

    setUsersOptionsAppend(
      state,
      action: PayloadAction<moduleState.IStateData['users']>
    ) {
      state.data.usersOptions = {
        items: [...state.data.usersOptions.items, ...action.payload.items],
        nextCursor: action.payload.nextCursor,
      }
    },

    setRoles(state, action: PayloadAction<moduleState.IRole[]>) {
      state.data.roles = action.payload
    },

    // setEditedUser(state, action: PayloadAction<moduleState.IUser>) {
    //   state.allUsers.data = state.allUsers.data?.map((user) => {
    //     if (user.id === action.payload.id) {
    //       return { ...action.payload }
    //     }

    //     return user
    //   })
    // },
    setConnectedAccounts(
      state,
      action: PayloadAction<moduleState.IStateData['accounts']>
    ) {
      state.data.accounts = action.payload
    },

    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData()
      clearAllStateCommunications(state.communication)
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions)
  },
})

export const setters = slice.actions
export const actions = moduleActions
export const reducers = { [moduleState.PREFIX]: slice.reducer }
export const sagas = moduleSagas
export const state = moduleState
export const selectors = moduleSelectors

export const injectStore = () => {
  store.injectReducer?.(moduleState.PREFIX, slice.reducer)
  store.injectSaga?.(moduleState.PREFIX, sagas)
}
