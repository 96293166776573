import { apiV2 } from 'src/services/storage/project/http'
import * as types from './types'

export * from './types'

const baseUrl = (params: types.IStageSortBaseUrlParams) =>
  `/scheme/${params.schemeName}/objects/${params.uniqId}/stage-field-update-sort/${params.fieldScheme}`

export const postStageSortStart = (params: types.IStageSortBaseUrlParams) =>
  apiV2.post(`${baseUrl(params)}/start`, {
    params,
  })

export const postStageSortEnd = (params: types.IStageSortBaseUrlParams) =>
  apiV2.post(`${baseUrl(params)}/end`, {
    params,
  })

export const postStageSortBefore = (params: types.IStageSortTargetParams) =>
  apiV2.post(`${baseUrl(params)}/before/${params.another}`)

export const postStageSortAfter = (params: types.IStageSortTargetParams) =>
  apiV2.post(`${baseUrl(params)}/after/${params.another}`)
