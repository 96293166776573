import { createHooksActions, createHooksSelectors } from 'src/helpers/redux'
import * as store from 'src/store/modules/twilio'

store.injectStore()
export { store }

export const useTwilioSelect = createHooksSelectors<
  typeof store.selectors.selectors
>(store.selectors.selectors)

export const useTwilioActions = createHooksActions<typeof store.actions>(
  store.actions,
  store.selectors.communications
)
