import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'
import { actions as sagaActions, PREFIX } from './actions'
import { IGlobalStore } from 'src/types/store'
import { IListItemGlobalReq } from './types'

export interface IState {
  communication: TStateCommunications<typeof sagaActions>
  globalSearchObject:
    | { object: IListItemGlobalReq; search: { [key: string]: unknown }[] }[]
    | null
}

export const initialState: IState = {
  communication: createStateSagaCommunications(sagaActions),
  globalSearchObject: null,
}

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState
}

export { PREFIX }
