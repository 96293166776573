import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Icon from 'src/components/ui/icon'
import { Settings } from 'src/context/settingsContext'
import UserDropdown from 'src/layouts/components/shared-components/UserDropdown'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  const { toggleNavVisibility, hidden, settings } = props

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        className="actions-left"
        sx={{
          mr: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {hidden && !settings.navHidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <Icon fontSize="1.5rem" icon="tabler:menu-2" />
          </IconButton>
        ) : null}
      </Box>
      <Box
        className="actions-right"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
