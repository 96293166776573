import React from 'react'

import { IListItemGlobalReq } from 'src/store/modules/globalSearch/types'
import UserIcon from 'src/components/ui/UserIcon'

import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar/Avatar'
import Typography from '@mui/material/Typography'

interface IDesiredObject {
  object: IListItemGlobalReq
  onClickHandler: (obj: IListItemGlobalReq) => void
}

const DesiredObject = (props: IDesiredObject) => {
  const { object, onClickHandler } = props

  return (
    <Stack
      onClick={() => onClickHandler(object)}
      spacing={4}
      sx={{
        transition: '0.2s',
        cursor: 'pointer',

        '&:hover': {
          background: (theme) => `${theme.palette.success.main}40`,
        },
      }}
      px={4}
      py={2}
      direction="row"
      alignItems="center"
    >
      <Stack
        direction="row"
        spacing={2}
        flexBasis={0}
        flexGrow={1}
        flexShrink={1}
        sx={{ overflow: 'hidden' }}
      >
        <Avatar
          sx={{ width: 32, height: 32 }}
          src={object.icon}
          alt={`${object.primary} ${object.secondary}`}
        />
        <Stack
          direction="column"
          flexBasis={0}
          flexGrow={1}
          flexShrink={1}
          sx={{ overflow: 'hidden' }}
        >
          <Typography
            textOverflow={'ellipsis'}
            fontSize={14}
            fontWeight={600}
            color={(theme) => theme.palette.grey[900]}
            noWrap
          >
            {object.primary}
          </Typography>
          <Typography
            fontSize={12}
            noWrap
            color={(theme) => theme.palette.grey[600]}
          >
            {object.secondary}
          </Typography>
        </Stack>
      </Stack>
      {object.parentObjectUniqId && (
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          py={1}
          px={2}
          sx={{
            color: (theme) => theme.palette.grey[900],
            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '15px',
          }}
        >
          {object.parentObject?.icon ? (
            <Avatar
              src={object.parentObject?.icon}
              sx={{ width: 25, height: 25 }}
            />
          ) : (
            <UserIcon icon="mdi:company" color="inherit" />
          )}
          <Typography fontSize={12} color="inherit">
            {object.parentObject?.primary}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default DesiredObject
