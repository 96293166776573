import { createPhaseActions, phaseAction } from 'src/helpers/redux'
import { IGetGlobalSearch } from './types'

export const PREFIX = 'global-search'
export const PREFIX_SAGE = `${PREFIX}/saga`

export const actions = createPhaseActions(PREFIX_SAGE, {
  getGlobalSearch: phaseAction<IGetGlobalSearch>(),
})
export type Actions = typeof actions
