import React from 'react'

import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'

const LoadingComponent = () => {
  return (
    <Stack direction="column" flex={1}>
      <Stack px={4} py={1}>
        <Skeleton width={100} />
      </Stack>

      {new Array(8).fill('').map((_, ind) => (
        <Stack key={ind} px={4} py={2} spacing={2} direction="row">
          <Skeleton variant="circular" width={32} height={32} />

          <Stack>
            <Skeleton variant="text" width={140} height={20} />
            <Skeleton variant="text" width={340} height={15} />
          </Stack>
        </Stack>
      ))}

      <Stack px={4} py={2} spacing={2} direction="row">
        <Skeleton variant="circular" width={32} height={32} />

        <Stack>
          <Skeleton variant="text" width={140} height={20} />
          <Skeleton variant="text" width={340} height={15} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default LoadingComponent
