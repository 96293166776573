import React from 'react'
import { useAccountSelect } from './useAccount'
import { IPermissions } from 'src/services/auth/users'
import getValue from 'get-value'

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}.${P}`
    : never
  : never

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

type Paths<T, D extends Prev[number] = 9> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T & (string | number)]: K | Join<K, Paths<T[K], Prev[D]>>
    }[keyof T & (string | number)]
  : ''

export type PermissionPath = Paths<IPermissions> | Paths<IPermissions>[]

export const usePermissions = useAccountSelect.permissions

export const usePermission = (
  permission?: PermissionPath,
  type = 'or'
): boolean => {
  const permissions = useAccountSelect.permissions()

  return React.useMemo(() => {
    if (!permissions) return false
    if (!permission) return true

    if (Array.isArray(permission)) {
      return Boolean(permission.find((rule) => getValue(permissions, rule)))
    }

    return Boolean(getValue(permissions, permission))
  }, [permissions, permission, type])
}
