import React from 'react'

import UserIcon from 'src/components/ui/UserIcon'

import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchResult from './SearchResult'
import Stack from '@mui/material/Stack'

import {
  useGlobalSearchActions,
  useGlobalSearchSelect,
} from 'src/hooks/useGlobalSearch'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

interface ISearchModal {
  openDialog: boolean
  onClose: () => void
}

const SearchModal = (props: ISearchModal) => {
  const { openDialog, onClose } = props
  const [search, setSearch] = React.useState('')

  const desiredObjects = useGlobalSearchSelect.globalSearchResult()
  const getGlobalSearchAction = useGlobalSearchActions.getGlobalSearch()
  const getGlobalSearchActionStatus =
    useGlobalSearchActions.getGlobalSearch.status()

  React.useEffect(() => {
    if (search) {
      getGlobalSearchAction({ search })
    }
  }, [search])

  const handleClearInput = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setSearch('')
  }

  return (
    <Dialog
      fullWidth
      open={openDialog}
      onClose={() => {
        onClose()
        setSearch('')
      }}
    >
      <Stack direction="column" sx={{ height: '600px' }}>
        <TextField
          value={search}
          variant="standard"
          fullWidth
          onChange={(event) => setSearch(event.target.value)}
          autoFocus
          InputProps={{
            autoComplete: 'off',
            sx: {
              p: (theme) => `${theme.spacing(3.75, 6)} !important`,
              '&.Mui-focused': { boxShadow: 0 },
            },
            startAdornment: (
              <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                <UserIcon fontSize="1.5rem" icon="tabler:search" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Stack direction="row" spacing={2} alignItems="center">
                  {search.length > 0 && (
                    <Button
                      onClick={handleClearInput}
                      variant="text"
                      size="small"
                      color="info"
                    >
                      <Typography color="info.dark">Clear</Typography>
                    </Button>
                  )}
                  {search.length > 0 && (
                    <Divider orientation="vertical" variant="middle" flexItem />
                  )}

                  <IconButton onClick={onClose} size="small" sx={{ p: 1 }}>
                    <UserIcon icon="tabler:x" fontSize="1.25rem" />
                  </IconButton>
                </Stack>
              </InputAdornment>
            ),
          }}
        />

        <SearchResult
          search={search}
          isFulfilled={getGlobalSearchActionStatus.isFulfilled}
          loading={getGlobalSearchActionStatus.isPending}
          data={desiredObjects}
          onCloseHandler={onClose}
        />
      </Stack>
    </Dialog>
  )
}

export default SearchModal
