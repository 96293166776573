// eslint-disable-next-line @typescript-eslint/no-empty-interface
import { IGetAccountData } from './auth/users'

export interface IStorage {
  user?: IGetAccountData
}

export const getInitialStorage = (): IStorage => {
  return {
    user: undefined,
  }
}

export const LOCAL_STORAGE_KEY = 'cusman-storage'

export const getStorage = (): IStorage => {
  const sessionData = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!sessionData) {
    return getInitialStorage()
  }

  try {
    const storage = JSON.parse(sessionData)

    return {
      ...getInitialStorage(),
      ...storage,
    }
  } catch (e) {
    console.error('invalid local storage')

    return getInitialStorage()
  }
}

export const setStorage = (storage: IStorage) => {
  try {
    const prevStorage = getStorage() || getInitialStorage()
    const toUpdatedStorage = { ...prevStorage, ...storage }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(toUpdatedStorage))
  } catch (e) {
    console.error(e)
  }
}

export const clearStorage = () => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getInitialStorage()))
  } catch (e) {
    console.error(e)
  }
}
