import React from 'react'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import UserIcon from 'src/components/ui/UserIcon'
import {
  ISchemaInfo,
  useStorageObjectsStore,
} from 'src/hooks/useStorageObjects'
import { useSearchQuery } from 'src/hooks/useSearchQuery'
import Divider from '@mui/material/Divider'

export interface IObjectViewNavigationProps {
  scheme: ISchemaInfo
  navHover?: boolean
  navVisible?: boolean
}
export const ObjectViewNavigation = (props: IObjectViewNavigationProps) => {
  const { scheme } = props
  const schemeName = scheme.identifier

  const { useSelect, useAction } = useStorageObjectsStore({
    prefix: 'primary',
    schemeName: scheme.identifier,
  })
  const getSmartListItemDefault = useAction.getSmartListItemDefault()
  const getSmartListItemDefaultStatus =
    useAction.getSmartListItemDefault.status()
  const getFieldsGroupsStatus = useAction.getFieldsGroups.status()
  const getFieldsGroups = useAction.getFieldsGroups()
  const fieldsShemes = useSelect.fieldsShemes()

  const boardList = useSelect.boardList()
  const { setPathQuery } = useSearchQuery()

  React.useEffect(() => {
    if (getSmartListItemDefaultStatus.isClear) {
      getSmartListItemDefault({})
    }
  }, [getSmartListItemDefaultStatus.isClear])

  React.useEffect(() => {
    if (getFieldsGroupsStatus.isClear || fieldsShemes.length) {
      getFieldsGroups({ limit: 50 })
    }
  }, [getFieldsGroupsStatus.isClear, fieldsShemes.length])

  if (!boardList || boardList?.length === 0) return <></>

  return (
    <Stack ml={3} onClick={(event) => event.stopPropagation()}>
      <List dense disablePadding>
        <ListItemButton
          key={'table'}
          dense
          onClick={() => {
            setPathQuery(`storage/${schemeName}`, {
              objectsViewType: 'table',
            })
          }}
        >
          <ListItemIcon>
            <UserIcon icon={'fluent:table-32-filled'} />
          </ListItemIcon>
          <ListItemText
            primary={<Stack sx={{ color: 'common.white' }}>{`Table`}</Stack>}
          />
        </ListItemButton>

        {boardList.map((board) => (
          <ListItemButton
            key={board.identifier}
            dense
            onClick={() => {
              setPathQuery(`storage/${schemeName}`, {
                objectsViewType: 'board',
                objectsViewId: board.identifier,
              })
            }}
          >
            <ListItemIcon>
              <UserIcon icon={'fluent:grid-kanban-16-filled'} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  sx={{ color: 'common.white' }}
                >{`Board ${board.title}`}</Stack>
              }
            />
          </ListItemButton>
        ))}
        <Divider sx={{ borderColor: 'grey.800', my: 0, ml: 5, mr: 8 }} />
      </List>
    </Stack>
  )
}
