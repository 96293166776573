import { SagaIterator } from 'redux-saga'
import { all, AllEffect } from 'redux-saga/effects'

import * as app from './modules/app'
import * as account from './modules/account'
import * as users from './modules/users'

export default function* root(): TRootSaga {
  yield all([app.sagas(), account.sagas(), users.sagas()])
}

export type TRootSaga = Generator<AllEffect<SagaIterator>, void, unknown>
