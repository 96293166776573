import toast from 'react-hot-toast'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import UserIcon from '../UserIcon'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import LinearProgress from '@mui/material/LinearProgress'

export enum ENotifyType {
  success = 'success',
  error = 'error',
}
export interface INotifyProps {
  id?: string
  type: 'success' | 'error' | 'warning' | 'progress'
  title: string
  description?: string
  icon?: string
  action?: {
    label: string
    navigate?: {
      path?: string
      query?: Record<string, any>
    }
  }
}

const getColorByType = (type: INotifyProps['type']) => {
  switch (type) {
    case 'success':
      return 'success'
    case 'progress':
      return 'success'
    case 'error':
      return 'error'
    case 'warning':
      return 'warning'
  }
}
const getIconByType = (type: INotifyProps['type']) => {
  switch (type) {
    case 'success':
      return 'mdi:success'
    case 'progress':
      return 'mdi:success'
    case 'error':
      return 'material-symbols:error'
    case 'warning':
      return 'material-symbols:warning'
  }
}
export const notify = (props: INotifyProps) =>
  toast((t) => {
    const { type, title, description, icon } = props

    const paletteColor = getColorByType(type)
    const currentIcon = icon || getIconByType(type)

    if (type === 'progress') {
      t.duration = Infinity
    }

    return (
      <Stack direction="row" spacing={3} py={2} pr={6}>
        <Stack>
          <Avatar
            sx={{
              bgcolor: `${paletteColor}.main`,
              width: 24,
              height: 24,
              mt: 1,
            }}
          >
            <UserIcon icon={currentIcon} />
          </Avatar>
        </Stack>

        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2" noWrap p={0} m={0}>
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
          {type === 'progress' && (
            <LinearProgress variant="indeterminate" sx={{ height: 2 }} />
          )}
        </Stack>
        <IconButton
          onClick={() => toast.dismiss(t.id)}
          size="small"
          sx={{ position: 'absolute', top: 2, right: 2 }}
        >
          <UserIcon icon="material-symbols:close" />
        </IconButton>
      </Stack>
    )
  })
