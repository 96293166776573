import { http } from 'src/services/accounts/http'

export enum Drivers {
  TELEGRAM = 'telegram',
  TWILIO = 'twilio',
}

export interface IGetAccounts {
  id?: number | string
  driver?: Drivers
  cursor?: string
}

export const getAccounts = ({ id, driver = Drivers.TWILIO }: IGetAccounts) => {
  return http.get(`/accounts`, {
    params: {
      userId: id,
      driver,
    },
  })
}
