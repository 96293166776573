import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { useSearchQuery } from 'src/hooks/useSearchQuery'

const Modal = React.memo((props: { modal?: string }) => {
  const { modal } = props

  if (modal) {
    const ModalComponent = React.lazy(() => import(`./${modal}`))

    return (
      <React.Suspense
        fallback={
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <ModalComponent />
      </React.Suspense>
    )
  } else {
    return <></>
  }
})

export const SearchAsyncModal = () => {
  const { query } = useSearchQuery()
  const timeout = React.useRef<NodeJS.Timeout | undefined>()
  const [modal, setModal] = React.useState(query.modal)

  React.useEffect(() => {
    if (timeout.current) {
      clearInterval(timeout.current)
    }
    if (query.modal === undefined) {
      timeout.current = setTimeout(() => {
        setModal(undefined)
      }, 1000)
    } else {
      setModal(query.modal)
    }
  }, [query.modal])

  return <Modal modal={modal} />
}
