/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put } from 'redux-saga/effects'
import { createSagas } from 'src/helpers/redux'
import store from 'src/store'

import * as twilioService from 'src/services/communication/twilio'

import { setters } from './index'
import { actions, Actions } from './actions'
import { selectors } from './selectors'
import { ICall } from './types'
import { IResponse } from 'src/services/accounts/http'

export default createSagas<Actions>(actions, {
  setWidgetOptions: function* ({ payload }) {
    yield put(setters.setWidgetState(payload))
  },

  setTwillioState: function* ({ payload }) {
    yield put(setters.setTwillioState(payload))
  },

  fetchMoreHistoryCalls: function* ({ payload }) {
    const callsHistory: IResponse<ICall[]> = yield call(
      twilioService.getCallHistory,
      payload
    )

    yield put(setters.setMoreHistoryCall(callsHistory))
  },

  getHistoryCalls: function* ({ payload }) {
    const callsHistory: IResponse<ICall[]> = yield call(
      twilioService.getCallHistory,
      payload
    )

    yield put(setters.setHistoryCalls(callsHistory))
    yield put(actions.getHistoryCalls.fulfilled())
  },

  makeOutgoingCall: function* ({ payload }) {
    const { toWhom, userId } = payload

    yield call(twilioService.makeOutgoingCall, {
      toWhom,
      userId,
    })
  },
})
