import { IGlobalStore } from 'src/types/store'
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'
import { actions as sagaActions, PREFIX } from './actions'
import { CallState, ICall, IToWhomCall, ToolConfiguration } from './types'

export interface IState {
  enabled: boolean
  communication: TStateCommunications<typeof sagaActions>
  widgetOptions: {
    microMuted: boolean
    callHolded: boolean
    tool: ToolConfiguration
    isFiltersOpen: boolean
    callState: CallState
    search: string
    selectedNumber?: string
  }
  data: {
    user: IToWhomCall
    callHistory: { data: ICall[]; cursor: null | string }
  }
}

export const initialState: IState = {
  enabled: false,
  widgetOptions: {
    isFiltersOpen: false,
    microMuted: false,
    callHolded: false,
    tool: ToolConfiguration.NONE,
    callState: CallState.ANSWERED,
    search: '',
  },
  data: {
    user: {
      number: '',
      name: '',
      avatarUrl: undefined,
      from: undefined,
    },
    callHistory: { data: [], cursor: null },
  },
  communication: createStateSagaCommunications(sagaActions),
}

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState
}

export { PREFIX }
