import { apiV2 } from 'src/services/storage/project/http'
import * as types from './types'

export * from './types'
export * from './objects'
export * from './fields'
export * from './fields-groups'
export * from './fields-groups'
export * from './comments'

export const getSchemas = (params: types.IGetSchemasParams) =>
  apiV2.get<types.IGetSchemasData>(`/scheme`, { params })

export const putSchemas = ({
  identifier,
  ...params
}: types.IPutSchemasParams) =>
  apiV2.patch<types.IPutSchemasData>(`/scheme/${identifier}`, params)

export const postSchemas = (params: types.IPostSchemasParams) =>
  apiV2.post<types.IPostSchemasData>(`/scheme`, params)

export const deleteSchemas = ({ identifier }: types.IDeleteSchemasParams) =>
  apiV2.delete<types.IDeleteSchemasData>(`/scheme/${identifier}`)
