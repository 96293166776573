import { http, apiV2 } from 'src/services/storage/project/http'
import * as types from './types'

export * from './types'

export const getFields = ({ schemeName, query }: types.IGetFieldsParams) =>
  http.get<types.IGetFieldsData>(`/scheme/${schemeName}/fields`, query)

export const postFields = ({ schemeName, data }: types.IPostFieldParams) =>
  http.post<types.IPostFieldData>(`/scheme/${schemeName}/fields`, data)

export const putFields = ({
  schemeName,
  identifier,
  data,
}: types.IPutFieldParams) =>
  http.put<types.IPutFieldData>(
    `/scheme/${schemeName}/fields/${identifier}`,
    data
  )

export const getFieldsById = ({
  schemeName,
  identifier,
}: types.IGetFieldsByIdParams) =>
  http.get<types.IGetFieldsByIdData>(
    `/scheme/${schemeName}/fields/${identifier}`
  )

export const deleteField = ({
  schemeName,
  identifier,
}: types.IDeleteFieldsByIdParams) =>
  http.delete<types.IDeleteFieldsByIdData>(
    `/scheme/${schemeName}/fields/${identifier}`
  )

export const postFieldsValidation = ({
  schemeName,
  data,
}: types.IPostFieldValidationParams) =>
  apiV2.post<types.IPostFieldData>(
    `/scheme/${schemeName}/fields/validate-only`,
    data
  )

export const putFieldsValidation = ({
  schemeName,
  identifier,
  data,
}: types.IPutFieldValidationParams) =>
  apiV2.post<types.IPutFieldData>(
    `/scheme/${schemeName}/fields/${identifier}/validate-only`,
    data
  )

export const putFieldsSortIndex = ({
  schemeName,
  identifier,
  sortIndex,
}: types.IPostFieldSortIndexParams) =>
  apiV2.post<types.IPutFieldData>(
    `/scheme/${schemeName}/fields/${identifier}/update-sort-index`,
    { sortIndex }
  )
