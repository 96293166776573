import { createPhaseActions, phaseAction } from 'src/helpers/redux'
import * as schemaServiceTypes from 'src/services/storage/project/scheme/types'
import { INotifyProps } from 'src/components/ui/notify/Notify'

export const PREFIX = `app`
export const PREFIX_SAGA = `${PREFIX}/sagas`

export const actions = createPhaseActions(PREFIX_SAGA, {
  tost: phaseAction<INotifyProps>(),
  toastDismiss: phaseAction<{ id: string }>(),
  navigate: phaseAction<{
    path?: string
    search?: Record<string, any>
    key?: string
    replaced?: boolean
  }>(),
  fieldsGroups: phaseAction<{
    schemaName: string
    items: schemaServiceTypes.IFieldsGroupData[]
    previousCursor?: string | null
    nextCursor?: string | null
  }>(),

  getSchemas: phaseAction<schemaServiceTypes.IGetSchemasParams>(),
  postSchemas: phaseAction<schemaServiceTypes.IPostSchemasParams>(),
  putSchemas: phaseAction<schemaServiceTypes.IPutSchemasParams>(),
  deleteSchemas: phaseAction<schemaServiceTypes.IDeleteSchemasParams>(),
  logging: phaseAction<void>(),
  cacheStorage: phaseAction<void>(),
})

export type Actions = typeof actions
