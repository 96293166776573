import Stack from '@mui/material/Stack'
import { NavItem } from './NavItem'
import { SmartListNavigation } from './SmartListNavigation'
import { ObjectViewNavigation } from './ObjectViewNavigation'
import { useAppSelect } from 'src/hooks/useApp'
import { useSearchQuery } from 'src/hooks/useSearchQuery'
import { ISchemaInfo } from 'src/hooks/useStorageObjects'
import { IUseRouterObjectTableSearchQuery } from 'src/hooks/router/useRouterObjectTable'

interface IGetDefaultView {
  objectsViewType: IUseRouterObjectTableSearchQuery['objectsViewType']
  objectsViewId: IUseRouterObjectTableSearchQuery['objectsViewId']
}

const NavItems = () => {
  const schemas = useAppSelect.schemas()
  const { setPathQuery } = useSearchQuery<IUseRouterObjectTableSearchQuery>()

  const getDefaultView = (scheme: ISchemaInfo): IGetDefaultView => {
    if (scheme.frontDefaultView) {
      const [objectsViewType, objectsViewId] =
        scheme.frontDefaultView.split('.')

      return {
        objectsViewType: objectsViewType as IGetDefaultView['objectsViewType'],
        objectsViewId,
      }
    }

    return {
      objectsViewType: 'table',
      objectsViewId: undefined,
    }
  }

  return (
    <Stack direction="column">
      {/* <NavItem
        title={'Dashboard'}
        path={`/`}
        icon={'tabler-layout-dashboard'}
      /> */}
      {schemas
        .filter((item) => item.showInMenu !== false)
        .map((scheme) => {
          const { objectsViewType, objectsViewId } = getDefaultView(scheme)

          return (
            <NavItem
              permission="storage.objects"
              title={scheme.title}
              key={scheme.id}
              path={`storage/${scheme.identifier}`}
              onClick={() => {
                setPathQuery(
                  `storage/${scheme.identifier}`,
                  {
                    objectsViewType,
                    objectsViewId,
                  },
                  false,
                  false
                )
              }}
              icon={'tabler-user'}
              content={() => (
                <Stack onClick={(event) => event.stopPropagation()}>
                  <ObjectViewNavigation scheme={scheme} />
                  <SmartListNavigation scheme={scheme} />
                </Stack>
              )}
            />
          )
        })}

      <NavItem
        title={'Tasks'}
        path={`/tasks`}
        icon={'tabler-list-details'}
        permission="tasks"
      />

      <NavItem
        title={'Mail'}
        path={`/email`}
        icon={'tabler-mail'}
        permission={'mail'}
      />

      <NavItem
        title={'Templates'}
        icon={'tabler-file-description'}
        permission="templates"
      >
        <NavItem
          title={'Email'}
          path={'/templates/email'}
          icon={'material-symbols:mail'}
          deep={1}
          permission="templates"
        />
        <NavItem
          title={'Messages'}
          path={'/templates/message'}
          icon={'jam:messages-f'}
          deep={1}
          permission="templates"
        />
        <NavItem
          title={'Docs'}
          path={'/templates/document'}
          icon={'simple-icons:googledocs'}
          deep={1}
          permission="templates"
        />
      </NavItem>

      <NavItem
        title={'Playbooks'}
        path={`/playbooks`}
        icon={'tabler-tournament'}
        permission={'playbooks'}
      />
      {/* <NavItem title={'Help'} path={`/help`} icon={'tabler-help'} /> */}
      <NavItem
        title={'Administration'}
        icon={'tabler-settings'}
        permission={[
          'auth.users',
          'storage.fieldsGroup',
          'storage.fieldsScheme',
          'storage.objectScheme',
        ]}
      >
        <NavItem
          title={'Users'}
          path={'/admin/users'}
          icon={'tabler-user-plus'}
          deep={1}
          permission={'auth.users'}
        />
        <NavItem
          title={'Users Groups'}
          path={'/admin/users-groups'}
          icon={'tabler-user-plus'}
          deep={1}
          permission={'auth.users'}
        />
        <NavItem
          title={'API Keys'}
          path={'/admin/api-keys'}
          icon={'tabler-settings'}
          deep={1}
          permission={'auth.users'}
        />

        <NavItem
          title={'Integration'}
          path={'/admin/integration'}
          icon={'tabler-settings'}
          deep={1}
          permission={'auth.users'}
        />

        <NavItem
          title={'Schemes'}
          path={'/admin/schemes'}
          icon={'tabler-settings'}
          deep={1}
          permission={'storage.objectScheme'}
        />
        {schemas.map((scheme) => (
          <NavItem
            key={scheme.id}
            title={scheme.title}
            deep={1}
            permission={['storage.fieldsGroup', 'storage.fieldsScheme']}
          >
            <NavItem
              title={'Group'}
              icon={'tabler-settings'}
              path={`/admin/groups/${scheme.identifier}`}
              deep={2}
              permission={'storage.fieldsGroup'}
            />
            <NavItem
              title={'Fields'}
              icon={'tabler-settings'}
              path={`/admin/fields/${scheme.identifier}`}
              deep={2}
              permission={'storage.fieldsScheme'}
            />
          </NavItem>
        ))}
      </NavItem>
      {/* <NavItem
        title={'TG Accounts'}
        path={'/tg-accounts'}
        icon={'tabler-brand-telegram'}
        permission={'communication.accounts'}
        deep={0}
      /> */}
      <NavItem
        title={'Numbers'}
        icon="material-symbols:settings"
        permission={'communication.accounts'}
      >
        <NavItem
          title={'Numbers'}
          path={'/numbers'}
          icon={'tabler-phone-plus'}
          deep={0}
          permission={'communication.accounts'}
        />
        <NavItem
          title={'Call history'}
          path={'/callHistory'}
          icon={'tabler-phone-plus'}
          deep={0}
          permission={'communication.accounts'}
        />
      </NavItem>
      <NavItem
        title={'Billing history'}
        path={`/billing-history`}
        icon={'tabler-billing'}
        // permission={'playbooks'}
      />
    </Stack>
  )
}

export default NavItems
