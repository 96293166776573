import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const InitialState = () => {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <Stack
        mb={4}
        sx={{
          width: 293,
          height: 110,
          backgroundImage: "url('/icons/SearchModalInitial.svg')",
        }}
      />
      <Typography fontSize={20} color={'grey.900'}>
        What are you searching for?
      </Typography>
      <Typography fontSize={14} color="grey.900">
        Search for favorite thing or find similar results area{' '}
      </Typography>
    </Stack>
  )
}

export default InitialState
