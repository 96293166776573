import { apiV2 } from 'src/services/storage/project/http'
import * as types from './types'

export * from './types'

export const getSmartList = ({
  schemeName,
  ...params
}: types.IGetSmartListParams) =>
  apiV2.get<types.IGetSmartListData>(`/scheme/${schemeName}/smart_list`, {
    params,
  })

export const getSmartListItem = (params: types.IGetSmartListItemParams) =>
  apiV2.get<types.IGetSmartListItemData>(
    `/scheme/${params.schemeName}/smart_list/${params.uniqId}`
  )
export const getSmartListItemDefault = (
  params: types.IGetSmartListItemDefaultParams
) =>
  apiV2.get<types.IGetSmartListItemData>(
    `/scheme/${params.schemeName}/smart_list/default`
  )

export const putSmartList = (params: types.IPutSmartListParams) =>
  apiV2.put<types.IGetSmartListData>(
    `/scheme/${params.schemeName}/smart_list/${params.uniqId}`,
    params.data
  )

export const putSmartListDefault = (params: types.IPutSmartListDefaultParams) =>
  apiV2.put<types.IGetSmartListData>(
    `/scheme/${params.schemeName}/smart_list/default`,
    {
      type: 'default',
      title: 'default',
      ...params.data,
    }
  )

export const postSmartList = (params: types.IPostSmartListParams) =>
  apiV2.post<types.IGetSmartListData>(
    `/scheme/${params.schemeName}/smart_list`,
    params.data
  )
export const postSmartListDefault = (
  params: types.IPutSmartListDefaultParams
) =>
  apiV2.post<types.IGetSmartListData>(
    `/scheme/${params.schemeName}/smart_list`,
    {
      type: 'default',
      title: 'default',
      ...params.data,
    }
  )
export const deleteSmartList = ({
  schemeName,
  uniqId,
}: types.IDeleteSmartListParams) =>
  apiV2.delete<types.IGetSmartListData>(
    `/scheme/${schemeName}/smart_list/${uniqId}`
  )
