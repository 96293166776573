import React from 'react'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import UserIcon from 'src/components/ui/UserIcon'
import {
  ISchemaInfo,
  useStorageObjectsStore,
} from 'src/hooks/useStorageObjects'
import { useRouterSmartList } from 'src/hooks/router/useRouterSmartList'
import Skeleton from '@mui/material/Skeleton'

export interface ISmartListNavigationProps {
  scheme: ISchemaInfo
  navHover?: boolean
  navVisible?: boolean
}
export const SmartListNavigation = (props: ISmartListNavigationProps) => {
  const { scheme } = props
  const schemeName = scheme.identifier

  const { useAction, useSelect } = useStorageObjectsStore({
    prefix: 'primary',
    schemeName,
  })
  const getSmartList = useAction.getSmartList()
  const getSmartListStatus = useAction.getSmartList.status()
  const {
    smartListSelectedId,
    openSmartListEdit,
    openSmartListNew,
    selectedSmartList,
  } = useRouterSmartList()
  const editButtonId = React.useId()
  const buttonEditId = `edit${editButtonId.replaceAll(':', '_')}`
  const smartListItems = useSelect.smartListItems()
  const smartListNextCursor = useSelect.smartListNextCursor()
  const isEmpty = getSmartListStatus.isFulfilled && smartListItems.length === 0

  React.useEffect(() => {
    if (getSmartListStatus.isClear) {
      getSmartList({ perPage: 5 })
    }
  }, [getSmartListStatus.isClear])

  const handleGetSmartListMore = () => {
    if (!getSmartListStatus.isClear) {
      getSmartList({ perPage: 5, cursor: smartListNextCursor as string })
    }
  }

  return (
    <Stack
      onClick={(event) => event.stopPropagation()}
      sx={{
        borderColor: 'grey.800',
        mb: 0,
        mt: 0,
      }}
    >
      <List dense disablePadding>
        {isEmpty && (
          <Stack height={30} alignItems="start" justifyContent="center" px={6}>
            <Button
              onClick={() =>
                openSmartListNew({
                  prefix: 'primary',
                  schemeName,
                })
              }
            >
              Create smart list
            </Button>
          </Stack>
        )}

        {smartListItems.map((item) => (
          <ListItemButton
            key={item.id}
            dense
            onClick={() =>
              selectedSmartList({
                prefix: 'primary',
                schemeName,
                id: item.id,
              })
            }
            sx={{
              pl: 8,
              [`& .${buttonEditId}`]: {
                opacity: 0,
              },
              [`&:hover .${buttonEditId}`]: {
                opacity: 1,
              },
            }}
          >
            <ListItemIcon
              sx={{
                color:
                  Number(smartListSelectedId) === item.id
                    ? 'primary.main'
                    : 'common.white',
              }}
            >
              <UserIcon
                icon={
                  item.type === 'manual'
                    ? 'eva:file-text-fill'
                    : 'material-symbols:neurology-outline'
                }
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  sx={{
                    color:
                      Number(smartListSelectedId) === item.id
                        ? 'primary.main'
                        : 'common.white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item.title}
                </Stack>
              }
            />
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                selectedSmartList({
                  prefix: 'primary',
                  schemeName,
                  id: item.id,
                })
                openSmartListEdit({
                  prefix: 'primary',
                  schemeName,
                  id: item.id,
                })
              }}
              sx={{ color: 'common.white' }}
              size="small"
              className={buttonEditId}
            >
              <UserIcon icon={'material-symbols:edit'} width={16} />
            </IconButton>
          </ListItemButton>
        ))}
        {getSmartListStatus.isPending && <SmartListSkeleton />}
        {smartListNextCursor && (
          <Button
            variant="text"
            onClick={handleGetSmartListMore}
            disabled={getSmartListStatus.isPending}
            sx={{ pl: 8, mt: 1 }}
            size="small"
            fullWidth
          >
            <Typography
              sx={{
                width: '100%',
                textAlign: 'left',
                color: 'primary.light',
                opacity: getSmartListStatus.isPending ? 0.5 : 1,
              }}
            >
              Show more
            </Typography>
          </Button>
        )}
      </List>
    </Stack>
  )
}

function SmartListSkeleton(props: { count?: number }) {
  const { count = 2 } = props

  return (
    <>
      {new Array(count).fill(null).map((_, index) => (
        <ListItemButton key={index} disabled sx={{ pl: 8 }}>
          <ListItemIcon>
            <Skeleton width={20} sx={{ backgroundColor: 'grey.50' }} />
          </ListItemIcon>
          <ListItemText>
            <Skeleton
              width={index % 2 ? 100 : 125}
              sx={{ backgroundColor: 'grey.50' }}
            />
          </ListItemText>
        </ListItemButton>
      ))}
    </>
  )
}
