import { useSearchQuery } from 'src/hooks/useSearchQuery'

export interface IUseRouterSmartListSearchQuery {
  modal?: string
  smartListSchemeName?: string
  smartListPrefix?: string
  smartListSelectedId?: string
}

export interface IUseRouterSmartListOpenEditProps {
  schemeName: string
  prefix: string
  id: number
}

export interface IUseRouterSmartListOpenNewProps {
  schemeName: string
  prefix: string
}

export interface IUseRouterSmartListSelectProps {
  schemeName: string
  prefix: string
  id?: number
}

export const useRouterSmartList = () => {
  const search = useSearchQuery<IUseRouterSmartListSearchQuery>()

  const openSmartListEdit = (props: IUseRouterSmartListOpenEditProps) => {
    search.setQuery({
      modal: 'smartList',
      smartListSchemeName: props.schemeName,
    })
  }
  const openSmartListNew = (props: IUseRouterSmartListOpenNewProps) => {
    search.setQuery({
      modal: 'smartList',
      smartListSchemeName: props.schemeName,
    })
  }
  const selectedSmartList = (props?: IUseRouterSmartListSelectProps) => {
    if (props?.id !== undefined) {
      search.setPathQuery(`/storage/${props.schemeName}`, {
        smartListSelectedId: String(props.id),
      })
    } else {
      search.removeQuery(['smartListSelectedId'])
    }
  }

  const close = () => {
    search.removeQuery(['modal', 'smartListSchemeName'])
  }

  return {
    search,
    ...search.query,
    close,
    openSmartListEdit,
    openSmartListNew,
    selectedSmartList,
  }
}
