import { createHooksActions, createHooksSelectors } from 'src/helpers/redux'
import * as store from 'src/store/modules/communication'

store.injectStore()
export { store }
export const useCommunicationSelect = createHooksSelectors<
  typeof store.selectors.selectors
>(store.selectors.selectors)

export const useCommunicationAction = createHooksActions<typeof store.actions>(
  store.actions,
  store.selectors.communications
)
