import { http } from 'src/services/auth/http'

export interface ILoginParams {
  identifier: string
  password: string
  type: 'email'
  authType: 'password'
}

export interface ILoginDataUser {
  id: number
}
export interface IGetUserDataInfo {
  firstName?: string
  jobTitle?: string
  lastName?: string
  address?: string
  zipcode?: string
  avatar?: string | File
  startWeekOnDay?: string
  preferredTimezone?: string
}

export interface ILoginData {
  accessToken: string
  expiresIn: number
  tokenType: 'bearer'
  user: ILoginDataUser
}
export interface IGetRefreshTokenData {
  accessToken: string
  expiresIn: number
}
export interface IGetUserParams {
  id: string | number
}

export interface IUserData {
  id?: number
  firstName?: string
  lastName?: string
  jobTitle?: string
  email?: string
  avatar?: string | File
  password?: string
  passwordConfirmation?: string
  groups?: number[]
}

export interface IGetUserData {
  id?: string | number
  info: IGetUserDataInfo
}
export interface IAccountPartner {
  id: number
  name: string
  state: string
  subdomain: string
  createdAt: string
  updatedAt: string
}

export interface IPermissions {
  auth?: {
    users?: {
      index?: boolean
      store?: boolean
      update?: boolean
    }
    partner?: {
      update?: boolean
    }
    groups?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
  }
  storage?: {
    objectScheme?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    fieldsGroup?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    fieldsScheme?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    objects?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
      import?: boolean
    }
    smartLists?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    comments?: {
      index?: boolean
      store?: boolean
      destroy?: boolean
      resolve?: boolean
    }
  }
  drive?: {
    files?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
      primary?: boolean
      template?: boolean
    }
  }
  communication?: {
    twilio?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    telegram?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    accounts?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
  }
  playbooks?: {
    index?: boolean
    store?: boolean
    update?: boolean
    destroy?: boolean
  }
  templates?: {
    index?: boolean
    store?: boolean
    update?: boolean
    destroy?: boolean
  }
  tasks?: {
    index?: boolean
    store?: boolean
    update?: boolean
    destroy?: boolean
  }
  mail?: {
    label?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    thred?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
    accounts?: {
      index?: boolean
      store?: boolean
      update?: boolean
      destroy?: boolean
    }
  }
}

export interface IAccountGroup {
  id: 3
  slug: string
  description: string
  created_at: string
  updated_at: string
  permissions: string[]
}
export interface IGetAccountData {
  id?: string | number
  info?: IGetUserDataInfo
  partner: IAccountPartner
  groups: IAccountGroup[]
  identifier?: string
}

export interface IInviteUsers {
  users: {
    email: string
    groupId: number
  }[]
}

export interface IGetAllUsers {
  perPage?: number
  cursor?: string
  search?: string
}

export const login = (data: ILoginParams) =>
  http.post<ILoginData>('/user/login', data)

export const getRefreshToken = () =>
  http.get<IGetRefreshTokenData>(`/user/refreshToken`)

export const getUser = (params: IGetUserParams) =>
  http.get<IGetUserData>(`/users/${params.id}`)

export const getAccount = () => http.get<IGetAccountData>(`/user`)

export const getAllUsers = (params?: IGetAllUsers) => {
  return http.get('/users', {
    params,
  })
}

export const editUserData = (props: IUserData) => {
  const { id } = props

  return http.put(`/users/${id}`, props)
}

export const editProfileData = (props: IGetUserDataInfo) => {
  return http.patch('/user', props)
}

export const editAvatarProfile = (props: { avatar: File | string }) => {
  return http.post('/user/avatar', props)
}

export const getAllAvailableRoles = () => {
  return http.get('/groups ')
}

export const inviteUsers = (props: IInviteUsers) =>
  http.post('/users/invite', props)
