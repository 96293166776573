/* eslint-disable @typescript-eslint/no-unused-vars */

import List from '@mui/material/List'
import Box from '@mui/material/Box'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles'
import PerfectScrollbar from 'react-perfect-scrollbar'
import themeConfig from 'src/theme/themeConfig'
import Drawer from './Drawer'
import VerticalNavItems from './VerticalNavItems'
import VerticalNavHeader from './VerticalNavHeader'
import themeOptions from 'src/theme/ThemeOptions'

const Navigation = () => {
  //@ts-ignore
  let darkTheme = createTheme(themeOptions({}, 'dark'))

  if (themeConfig.responsiveFontSizes) {
    darkTheme = responsiveFontSizes(darkTheme)
  }

  const handleInfiniteScroll = (ref: HTMLElement) => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect

      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect()

        return { ...original, height: Math.floor(original.height) }
      }
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Drawer>
        <VerticalNavHeader />

        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            maxWidth: '255px',
          }}
        >
          {/* @ts-ignore */}
          <PerfectScrollbar
            {...{
              options: { wheelPropagation: false },
              containerRef: (ref: any) => handleInfiniteScroll(ref),
            }}
          >
            <List
              className="nav-items"
              sx={{ pt: 0, '& > :first-child': { mt: '0' } }}
            >
              <VerticalNavItems />
            </List>
          </PerfectScrollbar>
        </Box>
      </Drawer>
    </ThemeProvider>
  )
}

export default Navigation
