import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import store from 'src/store'
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux'
import { actions as moduleActions } from './actions'
import * as moduleState from './state'
import moduleSagas from './sagas'
import * as moduleSelectors from './selectors'
import { IResponse } from 'src/services/auth/http'
import { IGetAccountData } from 'src/services/auth/users'

type AccountUpdatePayload = {
  [T in keyof IGetAccountData['info']]?: IGetAccountData['info'][T]
}

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setSession(
      state: moduleState.IState,
      action: PayloadAction<{
        accessToken: moduleState.IStateData['accessToken']
        userId: moduleState.IStateData['userId']
        isAfterLogin: moduleState.IStateData['isAfterLogin']
      }>
    ) {
      state.data.accessToken = action.payload.accessToken
      state.data.userId = action.payload.userId
      state.data.isAfterLogin = action.payload.isAfterLogin
    },
    setRefreshToken(
      state: moduleState.IState,
      action: PayloadAction<{
        accessToken: moduleState.IStateData['accessToken']
      }>
    ) {
      state.data.accessToken = action.payload.accessToken
    },
    setAllUsers(state, action: PayloadAction<IResponse<moduleState.IUser[]>>) {
      state.allUsers.data = action.payload.data
      state.allUsers.nextCursor = action.payload.meta.nextCursor
    },

    mergeUsers(state, action: PayloadAction<IResponse<moduleState.IUser[]>>) {
      state.allUsers.data = [...state.allUsers.data, ...action.payload.data]
      state.allUsers.nextCursor = action.payload.meta.nextCursor
    },

    setRoles(state, action: PayloadAction<moduleState.IRole[]>) {
      state.roles = action.payload
    },

    setEditedUser(state, action: PayloadAction<moduleState.IUser>) {
      state.allUsers.data = state.allUsers.data?.map((user) => {
        if (user.id === action.payload.id) {
          return { ...action.payload }
        }

        return user
      })
    },
    setConnectedAccounts(
      state,
      action: PayloadAction<moduleState.IState['accounts']>
    ) {
      state.accounts = action.payload
    },
    setProfileInfo(
      state: moduleState.IState,
      action: PayloadAction<AccountUpdatePayload>
    ) {
      if (state.data.user && action.payload) {
        //@ts-ignore
        state.data.user.info = {
          ...state.data.user.info,
          ...action.payload,
        }
      }
    },
    setProfile(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['user']>
    ) {
      if (action.payload) {
        state.data.user = action.payload?.id ? action.payload : state.data.user
      }
    },
    setBillingStat(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['billingStat']>
    ) {
      state.data.billingStat = action.payload
    },

    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData()
      clearAllStateCommunications(state.communication)
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions)
  },
})

export const setters = slice.actions
export const actions = moduleActions
export const reducers = { [moduleState.PREFIX]: slice.reducer }
export const sagas = moduleSagas
export const state = moduleState
export const selectors = moduleSelectors

export const injectStore = () => {
  store.injectReducer?.(moduleState.PREFIX, slice.reducer)
  store.injectSaga?.(moduleState.PREFIX, sagas)
}
