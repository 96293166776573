import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import UserIcon from 'src/components/ui/UserIcon'

const NothingCameUp = () => {
  return (
    <Stack
      direction="row"
      alignItems={'center'}
      justifyContent={'center'}
      flex={1}
    >
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{ width: 300 }}
      >
        <UserIcon icon="gg:smile-sad" fontSize={50} />

        <Stack>
          <Typography
            color={(theme) => theme.palette.grey[900]}
            textAlign={'center'}
            fontSize={20}
          >
            We couldn't find anything matching your search
          </Typography>
          <Typography textAlign={'center'}>Change your search term</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default NothingCameUp
